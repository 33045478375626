import { Injectable } from '@angular/core';
import * as departmentActions from '@app/modules/admission/_store/actions/departments.action';
import * as fromServices from '@app/core/_shared/services/departments/departments.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '../index';
import * as fromActions from '../actions';

@Injectable()
export class DepartmentEffects {
    constructor(
        private actions$: Actions,
        private departmentService: fromServices.DepartmentsService,
        private store: Store<fromStore.DashboardState>
    ) { }

    @Effect()
    loadDepartments$ = this.actions$.pipe(
        ofType(departmentActions.LOAD_DEPARTMENTS),
        switchMap((res: departmentActions.LoadDepartments) => {
            return this.departmentService.loadPaginated().pipe(
                map(
                    department =>
                        new departmentActions.LoadDepartmentsSuccess(
                            department.result
                        )
                ),
                catchError(error =>
                    of(new departmentActions.LoadDepartmentsFail(error))
                )
            );
        })
    );

    @Effect()
    loadMoreDepartments$ = this.actions$.pipe(
        ofType(departmentActions.LOAD_MORE_DEPARTMENTS),
        switchMap((res: departmentActions.LoadMoreDepartments) => {
            return this.departmentService.loadMore(res.payload.skip, res.payload.limit).pipe(
                map(
                    department =>
                        new departmentActions.LoadMoreDepartmentsSuccess(
                            department.result
                        )
                ),
                catchError(error =>
                    of(new departmentActions.LoadMoreDepartmentsFail(error))
                )
            );
        })
    );

    @Effect()
    loadAllDepartments$ = this.actions$.pipe(
        ofType(departmentActions.LOAD_ALL_DEPARTMENTS),
        switchMap((res: departmentActions.LoadAllDepartments) => {
            return this.departmentService.loadAll().pipe(
                map(
                    department =>
                        new departmentActions.LoadAllDepartmentsSuccess(
                            department.result.results
                        )
                ),
                catchError(error =>
                    of(new departmentActions.LoadAllDepartmentsFail(error))
                )
            );
        })
    );

    @Effect()
    searchDepartments$ = this.actions$.pipe(
        ofType(departmentActions.SEARCH_DEPARTMENTS),
        switchMap((res: departmentActions.SearchDepartments) => {
            return this.departmentService.search(res.payload).pipe(
                map(
                    department =>
                        new departmentActions.SearchDepartmentsSuccess(
                            department.result.results
                        )
                ),
                catchError(error =>
                    of(new departmentActions.SearchDepartmentsFail(error))
                )
            );
        })
    );

    @Effect()
    createDepartments$ = this.actions$.pipe(
        ofType(departmentActions.CREATE_DEPARTMENTS),
        switchMap((res: departmentActions.CreateDepartments) => {
            return this.departmentService.create(res.payload).pipe(
                map(department => new departmentActions.CreateDepartmentsSuccess(department.result)),
                catchError(error => of(new departmentActions.CreateDepartmentsFail(error)))
            )
        })
    );

    @Effect()
    deleteDepartments$ = this.actions$.pipe(
        ofType(departmentActions.DELETE_DEPARTMENTS),
        switchMap((res: departmentActions.DeleteDepartments) => {
            return this.departmentService.delete(res.payload.ids).pipe(
                map(() => new departmentActions.DeleteDepartmentsSuccess(res.payload)),
                catchError(error => of(new departmentActions.DeleteDepartmentsFail(error)))
            );
        })
    );

    @Effect({ dispatch: false })
    deleteSuccess$ = this.actions$.pipe(
        ofType(departmentActions.DELETE_DEPARTMENTS_SUCCESS),
        tap((res: any) => this.store.dispatch(new fromActions.LoadMoreDepartments({ skip: res.payload.skip, limit: res.payload.limit })))
    );

    @Effect()
    importDepartments$ = this.actions$.pipe(
        ofType(departmentActions.IMPORT_DEPARTMENTS),
        switchMap((res: departmentActions.ImportDepartments) => {
            return this.departmentService.import(res.payload).pipe(
                map(() => new departmentActions.ImportDepartmentsSuccess()),
                catchError(err => of(new departmentActions.ImportDepartmentsFail(err)))
            )
        })
    );

    @Effect({ dispatch: false })
    importSuccess$ = this.actions$.pipe(
        ofType(departmentActions.IMPORT_DEPARTMENTS_SUCCESS),
        tap(() => this.store.dispatch(new fromActions.LoadDepartments()))
    );

    @Effect({ dispatch: false })
    importFail$ = this.actions$.pipe(
        ofType(departmentActions.IMPORT_DEPARTMENTS_FAIL),
        tap(res => res)
    );
}
