import { Injectable } from '@angular/core';

declare let ga: any;

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsEventsService {
  public emitEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ): void {
    ga('send', 'event', {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue,
    });
  }

  public customPropreties(
    propertieCategory: string,
    propertieValue: string
  ): void {
    ga('set', propertieCategory, propertieValue);
    ga('send', 'pageview');
  }
}
