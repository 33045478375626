import { Action } from '@ngrx/store';


/**
 * Load All Units
 */

export const LOAD_ALL_UNITS = '[Dashboard] Load All Units';
export const LOAD_ALL_UNITS_SUCCESS = '[Dashboard] Load All Units Success';
export const LOAD_ALL_UNITS_FAIL = '[Dashboard] Load All Units Fail';

export class LoadAllUnits implements Action {
    readonly type = LOAD_ALL_UNITS;
}

export class LoadAllUnitsSuccess implements Action {
    readonly type = LOAD_ALL_UNITS_SUCCESS;
    constructor(public payload: any) {}
}

export class LoadAllUnitsFail implements Action {
    readonly type = LOAD_ALL_UNITS_FAIL;
    constructor(public payload: any) {}
}


/**
 * Load Units
 */

export const LOAD_UNITS = '[Dashboard] Load Units';
export const LOAD_UNITS_SUCCESS = '[Dashboard] Load Units Success';
export const LOAD_UNITS_FAIL = '[Dashboard] Load Units Fail';

export class LoadUnits implements Action {
    readonly type = LOAD_UNITS;
}

export class LoadUnitsSuccess implements Action {
    readonly type = LOAD_UNITS_SUCCESS;
    constructor(public payload: any) {}
}

export class LoadUnitsFail implements Action {
    readonly type = LOAD_UNITS_FAIL;
    constructor(public payload: any) {}
}

/**
 * Load More Units
 */

export const LOAD_MORE_UNITS = '[Dashboard] Load More Units';
export const LOAD_MORE_UNITS_SUCCESS = '[Dashboard] Load More Units Success';
export const LOAD_MORE_UNITS_FAIL = '[Dashboard] Load More Units Fail';

export class LoadMoreUnits implements Action {
    readonly type = LOAD_MORE_UNITS;
    constructor(public payload: any) {}
}

export class LoadMoreUnitsSuccess implements Action {
    readonly type = LOAD_MORE_UNITS_SUCCESS;
    constructor(public payload: any) {}
}

export class LoadMoreUnitsFail implements Action {
    readonly type = LOAD_MORE_UNITS_FAIL;
    constructor(public payload: any) {}
}

/**
 * Search Units
 */

export const SEARCH_UNITS = '[Dashboard] Search Units';
export const SEARCH_UNITS_SUCCESS = '[Dashboard] Search Units Success';
export const SEARCH_UNITS_FAIL = '[Dashboard] Search Units Fail';

export class SearchUnits implements Action {
    readonly type = SEARCH_UNITS;
    constructor(public payload: any) {}
}

export class SearchUnitsSuccess implements Action {
    readonly type = SEARCH_UNITS_SUCCESS;
    constructor(public payload: any) {}
}

export class SearchUnitsFail implements Action {
    readonly type = SEARCH_UNITS_FAIL;
    constructor(public payload: any) {}
}

export type UnitAction =
| LoadAllUnits
    | LoadAllUnitsSuccess
    | LoadAllUnitsFail
    | SearchUnits
    | SearchUnitsSuccess
    | SearchUnitsFail
    | LoadUnits
    | LoadUnitsSuccess
    | LoadUnitsFail
    | LoadMoreUnits
    | LoadMoreUnitsSuccess
    | LoadMoreUnitsFail;
