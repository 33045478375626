<header class="header-placeholder">
    <div>
        <span class="menu header-logo-item"></span>
        <span class="logo header-logo-item"></span>
        <span class="name-company header-logo-item"></span>
    </div>
    <div class="menu-search">
        <span class="busca header-logo-item"></span>
        <span class="user header-logo-item"></span>
        <span class="user-alternative header-logo-item"></span>
        <span class="user header-logo-item"></span>
        <span class="profile header-logo-item"></span>
    </div>
</header>
<div class="menu-nav-placeholder">
    <div>
        <span class="menu-item"></span>
        <span class="menu-item"></span>
        <span class="menu-item"></span>
    </div>
</div>
<div class="container-loading">
    <div class="loading">
        <div class="svg-go">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48.6 52.4" enable-background="new 0 0 48.6 52.4" xml:space="preserve">
                <path class="path" fill="none" stroke="#1172eb" stroke-width="3" stroke-miterlimit="10" d="M38,1.5H10.6c-5,0-9.1,4.1-9.1,9.1v23.1c0,5,4.1,9.1,9.1,9.1h26.6l6,7.4c1.4,1.4,3.9,0.4,3.9-1.6V10.6C47.1,5.6,43,1.5,38,1.5z"/>
            </svg>
        </div>
        <p>Carregando...</p>
    </div>
</div>
