import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromRoot from '@app/core/_store/reducers';
import * as fromDepartments from './departments.reducer';
import * as fromRoles from './roles.reducer';
import * as fromUnits from './units.reducer';
import * as fromOrganizations from './organizations.reducer';
import * as fromClinics from './clinics.reducer';
import * as fromPermissions from './permissions.reducer';
import * as fromModules from './modules.reducer';
import * as fromPreferences from './preferences.reducer';

export interface DashboardState {
    permissions: fromPermissions.PermissionState;
    modules: fromModules.ModuleState;
    organization: fromOrganizations.OrganizationState;
    units: fromUnits.UnitState;
    departments: fromDepartments.DepartmentState;
    roles: fromRoles.RoleState;
    clinics: fromClinics.ClinicState;
    preferences: fromPreferences.PreferenceState;
}

export interface State extends fromRoot.State {
    dashboard: DashboardState;
}

export const reducers: ActionReducerMap<DashboardState> = {
    permissions: fromPermissions.reducer,
    preferences: fromPreferences.reducer,
    modules: fromModules.reducer,
    organization: fromOrganizations.reducer,
    units: fromUnits.reducer,
    departments: fromDepartments.reducer,
    roles: fromRoles.reducer,
    clinics: fromClinics.reducer
};

export const getFilterState = createFeatureSelector<DashboardState>(
    'dashboard'
);
