import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SidebarAccountComponent } from './sidebar-account.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { RouterModule } from '@angular/router';
import { CompaniesModule } from '../companies/companies.module';
import { ReportsModule } from '../reports/reports.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        CompaniesModule,
        ReportsModule,
        Ng2SearchPipeModule
    ],
    declarations: [SidebarAccountComponent],
    exports: [SidebarAccountComponent]
})
export class SidebarAccountModule {}
