import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/_guards/auth/auth.service';

@Component({
    selector: 'app-content-layout',
    templateUrl: './content-layout.component.html',
    styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent implements OnInit {
    isOpen = false;
    year = new Date().getFullYear();
    isLoggedIn: boolean;

    constructor(private authService: AuthService) {}

    ngOnInit() {}

    onNotify(notify: boolean): void {
        this.isOpen = notify;
        this.isLoggedIn = this.authService.loggedIn();
    }

    onDeactivate() {
        window.scrollTo(0, 0);
    }
}
