import { switchMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as unitActions from '../actions/units.action';
import * as fromServices from '@app/core/_shared/services/units/units.service';

import { of } from 'rxjs';

@Injectable()
export class UnitEffects {
    constructor(
        private actions$: Actions,
        private unitService: fromServices.UnitsService
    ) {}

    @Effect()
    units$ = this.actions$.pipe(
        ofType(unitActions.LOAD_UNITS),
        switchMap((res: unitActions.LoadUnits) => {
            return this.unitService.read().pipe(
                map(unit => new unitActions.LoadUnitsSuccess(unit)),
                catchError(error => of(new unitActions.LoadUnitsFail(error)))
            );
        })
    );

    @Effect()
    loadMoreUnits$ = this.actions$.pipe(
        ofType(unitActions.LOAD_MORE_UNITS),
        switchMap((res: unitActions.LoadMoreUnits) => {
            return this.unitService.loadMore(res.payload).pipe(
                map(unit => new unitActions.LoadMoreUnitsSuccess(unit)),
                catchError(error =>
                    of(new unitActions.LoadMoreUnitsFail(error))
                )
            );
        })
    );

    @Effect()
    loadAllUnits$ = this.actions$.pipe(
        ofType(unitActions.LOAD_ALL_UNITS),
        switchMap((res: unitActions.LoadAllUnits) => {
            return this.unitService.loadAll().pipe(
                map(unit => new unitActions.LoadAllUnitsSuccess(unit)),
                catchError(error => of(new unitActions.LoadAllUnitsFail(error)))
            );
        })
    );

    @Effect()
    searchUnits$ = this.actions$.pipe(
        ofType(unitActions.SEARCH_UNITS),
        switchMap((res: unitActions.SearchUnits) => {
            return this.unitService.search(res.payload).pipe(
                map(unit => new unitActions.SearchUnitsSuccess(unit)),
                catchError(error => of(new unitActions.SearchUnitsFail(error)))
            );
        })
    );
}
