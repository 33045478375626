import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuardService } from '@app/core/_guards/auth/auth.guard';

import { CallbackComponent } from '@app/callback/callback.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';

import * as fromGuards from '@app/modules/admission/_guards';

const appRoutes: Routes = [
  { path: 'callback', component: CallbackComponent },
  { path: 'preauthorize', component: CallbackComponent },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        canActivate: [
          fromGuards.OrganizationGuard,
          fromGuards.PermissionAccountsGuard,
          fromGuards.ModulesGuard,
        ],
        loadChildren: () =>
          import('app/modules/admission/admission.module').then(
            (m) => m.AdmissionModule
          ),
      },
      {
        path: 'company',
        canActivate: [
          fromGuards.OrganizationGuard,
          fromGuards.PermissionAccountsGuard,
          fromGuards.ModulesGuard,
        ],
        loadChildren: () =>
          import('app/modules/settings/settings.module').then(
            (m) => m.DashboardCompanyModule
          ),
      },
      {
        path: 'preferences',
        canActivate: [
          fromGuards.OrganizationGuard,
          fromGuards.PermissionAccountsGuard,
          fromGuards.ModulesGuard,
        ],
        loadChildren: () =>
          import('app/modules/preferences/preferences.module').then(
            (m) => m.DashboardPreferencesModule
          ),
      },
      {
        path: 'search',
        canActivate: [
          fromGuards.OrganizationGuard,
          fromGuards.PermissionAccountsGuard,
          fromGuards.ModulesGuard,
        ],
        loadChildren: () =>
          import('app/modules/search/search.module').then(
            (m) => m.DashboardSearchModule
          ),
      },
      {
        path: 'models',
        canActivate: [
          fromGuards.OrganizationGuard,
          fromGuards.PermissionAccountsGuard,
          fromGuards.ModulesGuard,
        ],
        loadChildren: () =>
          import('app/modules/models/dashboard-models.module').then(
            (m) => m.DashboardModelsModule
          ),
      },
      {
        path: 'benefits',
        canActivate: [
          fromGuards.OrganizationGuard,
          fromGuards.PermissionAccountsGuard,
          fromGuards.ModulesGuard,
        ],
        loadChildren: () =>
          import('app/modules/benefits/dashboard-benefits.module').then(
            (m) => m.DashboardBenefitsModule
          ),
      },
      {
        path: 'collaborators',
        loadChildren: () =>
          import('app/modules/collaborator/collaborator.module').then(
            (m) => m.CollaboratorModule
          ),
      },
      {
        path: 'layouts',
        canActivate: [
          fromGuards.OrganizationGuard,
          fromGuards.PermissionAccountsGuard,
          fromGuards.ModulesGuard,
        ],
        loadChildren: () =>
          import('app/modules/layouts/layouts.module').then(
            (m) => m.LayoutsModule
          ),
      },
      {
        path: 'export/report',
        canActivate: [
          fromGuards.OrganizationGuard,
          fromGuards.PermissionAccountsGuard,
          fromGuards.ModulesGuard,
        ],
        loadChildren: () =>
          import('app/modules/admission/admission.module').then(
            (m) => m.AdmissionModule
          ),
      },
      {
        path: 'backup',
        canActivate: [
          fromGuards.OrganizationGuard,
          fromGuards.PermissionAccountsGuard,
          fromGuards.ModulesGuard,
        ],
        loadChildren: () =>
          import('app/modules/backup/backup-data.module').then(
            (m) => m.BackupDataModule
          ),
      },
      {
        path: 'signatures',
        canActivate: [
          fromGuards.OrganizationGuard,
          fromGuards.PermissionAccountsGuard,
          fromGuards.ModulesGuard,
        ],
        loadChildren: () =>
          import('app/modules/signatures/signatures.module').then(
            (m) => m.SignaturesModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
  providers: [...fromGuards.guards],
})
export class AppRoutingModule {}
