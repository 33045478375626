import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromDepartments from '../reducers/departments.reducer';

export const getDepartmentState = createSelector(
    fromFeature.getFilterState,
    (state: fromFeature.DashboardState) => state.departments
);

export const getDepartmentsEntities = createSelector(
    getDepartmentState,
    fromDepartments.getDepartmentsEntities
);

export const getTotalDepartments = createSelector(
    getDepartmentState,
    fromDepartments.getTotalDepartments
);
export const getPaginatedDepartments = createSelector(
    getDepartmentsEntities,
    entities => {
        return Object.keys(entities).map(uid => entities[uid]);
    }
);
export const getDepartmentsLoaded = createSelector(
    getDepartmentState,
    fromDepartments.getDepartmentsLoaded
);
export const getDepartmentsLoading = createSelector(
    getDepartmentState,
    fromDepartments.getDepartmentsLoading
);

/**
 * All Departments
 */
export const getAllDepartmentsEntities = createSelector(
    getDepartmentState,
    fromDepartments.getAllDepartmentsEntities
);
export const getAllDepartments = createSelector(
    getAllDepartmentsEntities,
    allDepartments => allDepartments
);
export const getAllDepartmentsLoaded = createSelector(
    getDepartmentState,
    fromDepartments.getAllDepartmentsLoaded
);
export const getAllDepartmentsLoading = createSelector(
    getDepartmentState,
    fromDepartments.getAllDepartmentsLoading
);

/**
 * Importing departments state
 */
export const getDepartmentsImporting = createSelector(
    getDepartmentState,
    fromDepartments.getDepartmentsImporting
);
export const getDepartmentsImported = createSelector(
    getDepartmentState,
    fromDepartments.getDepartmentsImported
);
