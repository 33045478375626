import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { ActivatedRoute } from '@angular/router';

import { NgxPermissionsService } from 'ngx-permissions';

import { switchMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import * as permissionActions from '../actions/permissions.action';
import * as fromServices from '@app/core/_shared/services/permissions/permissions.service';
import * as fromDashboardStore from '@app/modules/admission/_store';

@Injectable()
export class PermissionEffects {
    constructor(
        private actions$: Actions,
        private store: Store<fromDashboardStore.DashboardState>,
        private permissionService: fromServices.PermissionsService,
        private permService: NgxPermissionsService,
        private route: ActivatedRoute
    ) {}

    @Effect()
    accountsPermissions$ = this.actions$.pipe(
        ofType(permissionActions.LOAD_ACCOUNTS_PERMISSIONS),
        switchMap((res: permissionActions.LoadAccountsPermissions) => {
            return this.permissionService
                .getAccountsPermissions(res.payload)
                .pipe(
                    map(
                        permission =>
                            new permissionActions.LoadAccountsPermissionsSuccess(
                                permission
                            )
                    ),
                    retry(2),
                    catchError(error =>
                        of(
                            new permissionActions.LoadAccountsPermissionsFail(
                                error
                            )
                        )
                    )
                );
        })
    );

    @Effect()
    unitsPermissions$ = this.actions$.pipe(
        ofType(permissionActions.LOAD_UNITS_PERMISSIONS),
        switchMap((res: permissionActions.LoadUnitsPermissions) => {
            return this.permissionService.getUnitsPermissions(res.payload).pipe(
                map(
                    permission =>
                        new permissionActions.LoadUnitsPermissionsSuccess(
                            permission
                        )
                ),
                retry(2),
                catchError(error =>
                    of(new permissionActions.LoadUnitsPermissionsFail(error))
                )
            );
        })
    );

    @Effect({ dispatch: false })
    callbackAccountsSuccess$: Observable<any> = this.actions$.pipe(
        ofType(permissionActions.LOAD_ACCOUNTS_PERMISSIONS_SUCCESS),
        tap(value => {
            const permissions = value.payload;
            Object.keys(permissions).forEach(key => {
                this.permService.loadPermissions(permissions[key]);
            });
        })
    );

    @Effect({ dispatch: false })
    callbackUnitsSuccess$: Observable<any> = this.actions$.pipe(
        ofType(permissionActions.LOAD_UNITS_PERMISSIONS_SUCCESS),
        tap(value => {
            const permissions: any = value.payload;
            let unit: string;
            this.route.queryParams.subscribe(params => {
                unit = params.u;
            });

            Object.keys(permissions).forEach(key => {
                if (key === `unit:${unit}`) {
                    this.permService.loadPermissions(permissions[key]);
                }
            });
        })
    );
}
