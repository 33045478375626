declare let require: any;
import * as env from '../assets/js/env5.8c49dfbd2bef70823b90.js';

export const environment = {
    production: true,
    url: env.url,
    cdn_url: env.cdn_url,
    segmentApiKey: env.segmentApiKey,
    base_sign_url: env.base_sign_url,
    productId: env.productId,
    app_version: env.app_version,
    sentry_enabled: env.sentry_enabled,
    sentry_dsn: env.sentry_dsn,
    sentry_ignore_errors: env.sentry_ignore_errors,
    sentry_ignore_status_code_errors: env.sentry_ignore_status_code_errors,
    sign_envelopes_management_url: env.sign_envelopes_management_url,
    sign_callback_url: env.sign_callback_url,
    sign_people_product_id: env.sign_people_product_id,
    report_versions: env.report_versions,
    mixpanelToken: '74f711481296bc88e62c883781fc60b5',
  };
