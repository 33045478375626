export enum SegmentNameEnum {
  '/company/models' = 'People - Models - Page View',
  '/company/models-list' = 'People - Models - Page View',
  '/add' = 'People - New Position - Page View',
}

export enum SegmentTitlePage {
  '/company/models' = 'Modelos',
  '/company/models-list' = 'Modelos',
  '/home/' = 'Home Dashboard',
  '/company/benefits/' = 'Listagem Beneficios',
  '/add' = 'Criando um novo cadastro',
}

export enum SegmentEventName {
    'add_model_btn_click' = 'People - New Model - Add Button Click',
    'new_model_modal_view' = 'People - New Model - Modal View',
    'model_editor_page_view' = 'People - Model Editor - Page View',
    'signature_new_model_modal_view' = 'People - Models - New Model Modal View',
    'signature_new_text_model_btn_click' = 'People - Models - New Text Model Button Click',
    'signature_new_pdf_model_btn_click' = 'People - Models - New PDF Model Button Click',
    'open_model_click' = 'People - Models - Open Model Click',
    'new_model_btn_click' = 'People - Models - New Model Click',
    'candidate_actions_btn_click' = 'People - Candidate Actions - Actions Button Click',
    'sign_signature_btn_blick' = 'People - Candidate Actions - Sign Signature Button Click',
    'resend_link_btn_click' = 'People - Candidate Actions - Resend Link Button Click',
    'export_documents_btn_click' = 'People - Candidate Actions - Export Documents Button Click',
    'send_admission_btn_click' = 'People - Candidate Actions - Send Admission Kit Button Click',
    'resend_link_modal_view' = 'People - Resend Link - Modal View',
    'resend_link_modal_view_success' = 'People - Resend Link - Success Modal View',
    'resend_btn_click' = 'People - Resend Link - Resend Button Click',
    'export_documents_modal_view' = 'People - Export documents - Modal View',
    'export_documents_export_btn_click' = 'People - Export documents - Export Button Click',
    'send_admission_kit_modal_view' = 'People - Send Admission Kit - Modal View',
    'send_admission_kit_send_btn' = 'People - Send Admission Kit - Send Button Click',
    'send_admission_kit_added_models' = 'People - Send Admission Kit - Add Models Modal View',
    'send_admission_kit_added_modal_view' = 'People - Send Admission Kit - Added Models Modal View',
    'send_admission_kit_success' = 'People - Send Admission Kit - Success Modal View',
    'adicionar_modelo' = 'People - New Model - Add Button Click',
    'page_novo_modelo' = 'People - New Model - Modal View',
    'page_editar_modelo' = 'People - Model Editor - Page View',
    'listar_templates' = 'People - Models - Open Model Click',
    'redo' = 'People - Model Editor - Redo Button Click',
    'undo' = 'People - Model Editor - Undo Button Click',
    'insertTable' = 'People - Model Editor - Insert Table Button Click',
    'imageUpload' = 'People - Model Editor - Insert Image Button Click',
    'home_export_btn_click' = 'People - Home - Export Button Click',
    'export_listing_modal_view' = 'People - Export Listing - Modal View',
    'export_listing_candidates_btn_click' = 'People - Export Listing - Candidates Button Click',
    'export_listing_benefits_btn_click' = 'People - Export Listing - Benefits Button Click',
    'export_listing_candidate_export_btn_click' = 'People - Export Listing - Candidate Export Button Click',
    'export_listing_benefits_export_btn_click' = 'People - Export Listing - Benefits Export Button Click',
    'export_payroll_modal' = 'People - Export To Payroll - Modal View',
    'export_payroll_btn_click' = 'People - Export To Payroll - Export Button Click',
    'export_generate_report_modal_ok' = 'People - Export Status - OK I Got It Button Click',
    'export_download_modal_view' = 'People - Export Status - Modal View',
    'export_download_modal_click' = 'People - Export Status - Download Link Click',
    'export_download_modal_close' = 'People - Export Status - Close Button Click',
    'export_download_modal_unavailable_close' = 'People - Export Status - Close Button Click',
    'settings_roles' = 'People - Export Roles - Modal View',
    'settings_departments' = 'People - Export Departments - Modal View',
    'settings_clinics' = 'People - Export Clinics - Modal View',
    'export_roles' = 'People - Export Roles - Export Button Click',
    'export_departments' = 'People - Export Departments - Export Button Click',
    'export_clinics' = 'People - Export Clinics - Export Button Click',
    'backup_request_button_click' = 'People - Request Data Backup - Request Backup Button Click',
    'backup_request_modal_view' = 'People - Request Data Backup - Modal View',
    'backup_request_try_again_button_click' = 'People - Request Data Backup - Try Again Button Click',
    'backup_request_security_tips' = 'People - Request Data Backup - Security Tips Link Click',
    'backup_request_download_button_click' = 'People - Request Data Backup - Download Button Click',
    'backup_download_modal_view' = 'People - Download Data Backup - Modal View',
    'refuse_btn_click' = 'People - Candidate Profile - Notification Refuse Button Click',
    'refuse_confirm_click' = 'People - Candidate Profile - Refuse Document Confirm Button Click',
    'approve_btn_click' = 'People - Candidate Profile - Notification Approve Button Click',
    'notification_confirm_btn_click' = 'People - Candidate Profile - Notification Confirm Button Click',
    'page_benefits' = 'People - Benefits Module - Page view',
    'page_view_benefits' = 'People: Benefits Module - Page View Page',
    'card_food_benefits' = 'People: Benefits Module - Card Food',
    'card_basic_basket_benefits' = 'People: Benefits Module - Card Basic Basket',
    'add_benefit_button' = 'People: Benefits Module - Add Benefit Button',
    'modal_new_befits_view' = 'People: Benefits Module - Page View Modal New Benefits',
    'modal_new_benefits_cancel' = 'People: Benefits Module Modal - Cancel Button',
    'modal_new_benefits_add' = 'People: Benefits Module Modal - Add Button',
    'setup_food_cancel' = 'People: Benefits Module Setup Page - Cancel Button',
    'setup_food_add' = 'People: Benefits Module Setup Page - Add Button',
    'registered_add_button_food_benefit' = 'People: Registered Benefits Page - Button Benefits',
    'registered_edit_button_benefit' = 'People: Registered Benefits Page - Button Edit',
    'registered_delete_button_food_benefit' = 'People: Registered Benefits Page - Button Delete',
    'setup_cesta_basica_cancel' = 'People: Benefits Setup Page - Cancel Button',
    'setup_cesta_basica_add' = 'People: Benefits Setup Page - Add Button',
    'new_position_btn_click' = 'People - New Position - New Position Button Click',
    'page_view_new_position' = 'Viewed People - New Position - Page View Page',
    'new_position_generate_btn_click' = 'People - New Position - Generate Position Button Click',
    'duplicate_position_btn_click' = 'People - Home - Duplicate Candidate Button Click',
    'candidate_action_menu_btn_click' = 'People - Home - Candidate Action Menu Click',
    'page_view_new_position_share' = 'Viewed People - New Position Share - Page View Page',
    'new_position_share_copy_btn_click' = 'People - New Position Share - Copy Button Click',
    'new_position_share_send_btn_click' = 'People - New Position Share - Send Button Click',
    'new_position_share_modal_view' = 'People - New Position Share - Modal View',
    'new_position_alert_message_eligibility' = 'Viewed People - New Position - Alert Message',
    'new_position_edit_benefits_with_rule' = 'Viewed People - Edit Benefits',
    'new_position_modal_edit_benefits_with_rule' = 'Viewed People - Modal Edit Benefits',
    'page_view_new_position_alert_benefits' = 'People Settings - Modal Eligibility rule error - View Modal',
    'new_position_alert_benefits_btn_edit' = 'People Settings - Modal Eligibility rule error - Button Edit',
    'new_position_alert_benefits_btn_continue' = 'People Settings - Modal Eligibility rule error - Button Continue',
    'new_position_creadeted_with_rules_eligibility' = 'People Settings - Position created with eligibility rule',
    'new_position_creadeted_discarting_eligibility_rules' = 'People Settings - Position created by discarding eligibility rule',
    'new_position_admissional_kit_eligibility_message_click' = 'Viewed People - New Position - Alert Message',
    'new_position_creadeted_with_rules_eligibility_adm_kit' = 'People Settings - Position created with eligibility rule - Admission-kit',
    'new_position_created_modal_success' = 'People - New position - Share I got it Button Click',
    'disregard_document' = 'People - Candidate Documents - Disregard Button Click',
    'download_document' = 'People - Candidate Documents - Download Button Click',
    'visualization_document' = 'People - Candidate Documents - Document Visualization Modal View',
    'collaborator_activation' = 'People - Activate Collaborator - Activate Collaborator Button Click',
    'collaborator_activation_modal' = 'People - Activate Collaborator - Modal View',
    'collaborator_activation_click' = 'People - Activate Collaborator - Activate Button Click',
    'collaborator_open_profile' = 'People - Activate Collaborator - See Profile Button Click',
    'decline_candidate_link' = 'People - Candidate Profile - Decline Candidate Link Click',
    'candidate_declined_link' = 'People - Candidate Profile - Candidate Declined Link Click',
    'decline_candidate_modal' = 'People - Decline Candidate Modal - Decline Button Click',
    'resume_candidate_modal' = 'People - Candidate Profile - Resume Admission Modal View',
    'resume_candidate_send_click' = 'People - Candidate Profile - Resume Admission Send Button Click',
    'resume_candidate_copy_click' = 'People - Candidate Profile - Resume Admission Copy Button Click',
    'candidate_declined_modal' = 'People - Candidate Declined Modal - Save Button Click',
    'viewed_people' = "Viewed People - Home - Page View Page",
    'declines_status_button_click' = "People - Declines - Status Button Click",
    'declines_status_filter' = "People - Declines - Status Filter",
    'declines_reason_button_click' = "People - Declines - Reason Button Click",
    'declines_reason_filter' = "People - Declines - Reason Filter",
    'authentication_time' = "People - Authentication - Time",
    'document_details' = 'People - Collaborator Profile Signatures - Document Data Button Click',
    'document_opened' =  'People - Collaborator Profile Signatures - Visualize Button Click',
    'document_download' =  'People - Collaborator Profile Signatures - Download Button Click',
    'document_sidepanel' = 'People - Collaborator Profile - Document Info Side Panel View',
    'document_show_cpf' = 'People - Collaborator Profile Signatures - Show CPF Button Click',
}

export enum SegmentPageView {
  'home_pageview' = 'People - Home - Page View',
  'settings_roles' = 'People - Roles - Page View',
  'settings_departments' = 'People - Departments - Page View',
  'settings_clinics' = 'People - Medical Clinic - Page View',
  'data_backup' = 'People - Data Backup - Page View'
}
