import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

declare let environment: any;

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    private url: string = environment.url;
    private account: string;

    constructor(private http: HttpClient, private store: Store<any>) {
        this.store.select('dashboard').subscribe(res => {
            this.account = res.organization.currentAccount.uid;
        });
    }

    read(query: string): Observable<any> {
        return this.http.get(
            `${this.url}/search/people?account=${this.account}&q=${query}`
        );
    }

    preview(query: string): Observable<any> {
        return this.http.get(
            `${this.url}/search/people/preview?account=${this.account}&q=${query}`
        );
    }
}
