import mixpanel from 'mixpanel-browser';
import { TrackingProvider } from "./tracking-provider.interface";

export class MixpanelTrackingProvider implements TrackingProvider {
  track(title: string, properties?: any): void {
    mixpanel.track(title, properties);
  }

  trackPageView(title: string, properties?: any): void {
    mixpanel.track_pageview(properties, { event_name: title });
  }

  identifyUser(user: string, properties?: any): void {
    mixpanel.identify(user);
    mixpanel.people.set(properties);
  }

  aliasUser(user: string, properties?: any): void {
    mixpanel.alias(user);
    mixpanel.people.set(properties);
  }
}
