import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromPermissions from '../reducers/permissions.reducer';

export const getPermissionState = createSelector(
    fromFeature.getFilterState,
    (state: fromFeature.DashboardState) => state.permissions
);

export const getPermissionsAccountsEntities = createSelector(
    getPermissionState,
    fromPermissions.getPermissionsAccountsEntities
);

export const getPermissionsUnitsEntities = createSelector(
    getPermissionState,
    fromPermissions.getPermissionsUnitsEntities
);

/**
 * Accounts Permissions Selectors
 */
export const getAllAccountsPermissions = createSelector(
    getPermissionsAccountsEntities,
    entities => {
        return Object.keys(entities).map(uid => entities[uid]);
    }
);

export const getAccountsPermissionsLoaded = createSelector(
    getPermissionState,
    fromPermissions.getPermissionsAccountsLoaded
);

export const getAccountsPermissionsLoading = createSelector(
    getPermissionState,
    fromPermissions.getPermissionsAccountsLoading
);

/**
 * Units Permissions Selectors
 */

export const getAllUnitsPermissions = createSelector(
    getPermissionsUnitsEntities,
    entities => {
        return entities;
    }
);

export const getUnitsPermissionsLoaded = createSelector(
    getPermissionState,
    fromPermissions.getPermissionsUnitsLoaded
);

export const getUnitsPermissionsLoading = createSelector(
    getPermissionState,
    fromPermissions.getPermissionsUnitsLoading
);
