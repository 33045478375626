import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderModule } from '@app/layout/content-layout/components/header/header.module';
import { SidebarAccountModule } from '@app/layout/content-layout/components/sidebar-account/sidebar-account.module';

import { ContentLayoutComponent } from './content-layout.component';

@NgModule({
    declarations: [ContentLayoutComponent],
    exports: [ContentLayoutComponent],
    imports: [CommonModule, RouterModule, HeaderModule, SidebarAccountModule],
})
export class ContentLayoutModule {}
