import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

declare let environment: any;

@Injectable({ providedIn: 'root' })
export class ModulesService {
    private url: string = environment.url;

    constructor(private http: HttpClient) {}

    /**
     * Get modules permissions
     * @param access_token JWT token
     */
    read(access_token): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${access_token}`
            }),
            withCredentials: true
        };
        return this.http.get(`${this.url}/modules`, httpOptions);
    }
}
