import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap, filter, take, switchMap, catchError, map } from 'rxjs/operators';

import * as fromStore from '../_store';

declare let environment;

@Injectable()
export class ModulesGuard implements CanActivate {
    private readonly signUrl: string = environment.sign_url;
    private readonly baseUrl: string = environment.base_url;

    constructor(private store: Store<fromStore.DashboardState>) {}

    canActivate(): Observable<any> {
        return this.checkStore().pipe(
            switchMap(() => {
                return this.checkRedirect();
            }),
            catchError(() => of(false))
        );
    }

    checkRedirect(): Observable<boolean> {
        let result: Observable<boolean> = of(true);
        this.checkModules().subscribe((modules) => {
            const hasAdmission = modules.admissions;
            const hasSignature = modules.signature;
            if (hasAdmission) {
                result = of(true);
            } else if (hasSignature) {
                window.location.href = `${this.signUrl}/login-integration?product_id=99c3dfc5-7518-4d7c-9e75-e54d920721fd&redirect_uri=${this.baseUrl}`;
                result = of(false);
            }
        });
        return result;
    }

    checkModules(): Observable<any> {
        return this.store.pipe(select(fromStore.getModulesEntities));
    }

    checkStore(): Observable<boolean> {
        return this.store.pipe(
            select(fromStore.getModulesLoaded),
            tap((loaded) => {
                if (!loaded) {
                    this.store.dispatch(
                        new fromStore.LoadModules(localStorage.getItem('token'))
                    );
                }
            }),
            filter((loaded) => loaded),
            take(1)
        );
    }
}
