import * as fromClinics from '../actions/clinics.action';

import { Clinic } from '@app/core/_shared/models/clinics.model';

export interface ClinicState {
    entities: Array<Clinic>;
    allClinics: Array<Clinic>;
    loaded: boolean;
    loading: boolean;
    allLoaded: boolean;
    allLoading: boolean;
    total: Number;
    imported: boolean;
    importing: boolean;
}

export const initialState: ClinicState = {
    entities: [],
    allClinics: [],
    loaded: false,
    loading: false,
    allLoaded: false,
    allLoading: false,
    total: 0,
    imported: false,
    importing: false,
};

export function reducer(
    state = initialState,
    action: fromClinics.ClinicAction
): ClinicState {
    switch (action.type) {
        case fromClinics.LOAD_CLINICS: {
            return { ...state, loading: true };
        }

        case fromClinics.LOAD_CLINICS_SUCCESS: {
            const clinics = action.payload;
            const total = clinics.total;

            const entities = clinics.results.reduce(
                (all: { [uid: string]: Clinic }, item: Clinic) => {
                    return { ...all, [item.uid]: item };
                },
                {}
            );
            return { ...state, loading: false, loaded: true, entities, total };
        }

        case fromClinics.LOAD_CLINICS_FAIL: {
            return { ...state, loading: false, loaded: false };
        }

        case fromClinics.LOAD_MORE_CLINICS: {
            return { ...state, loading: true };
        }

        case fromClinics.LOAD_MORE_CLINICS_SUCCESS: {
            const clinics = action.payload;
            const total = clinics.total;

            const entities = clinics.results.reduce(
                (all: { [uid: string]: Clinic }, item: Clinic) => {
                    return { ...all, [item.uid]: item };
                },
                {}
            );

            return { ...state, loading: false, loaded: true, entities, total };
        }

        case fromClinics.LOAD_MORE_CLINICS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        case fromClinics.LOAD_ALL_CLINICS: {
            return { ...state, allLoading: true };
        }

        case fromClinics.LOAD_ALL_CLINICS_SUCCESS: {
            const allClinics = action.payload;

            return { ...state, allLoading: false, allLoaded: true, allClinics };
        }

        case fromClinics.LOAD_ALL_CLINICS_FAIL: {
            return {
                ...state,
                allLoading: false,
                allLoaded: false
            };
        }

        case fromClinics.SEARCH_CLINIC: {
            return { ...state, loading: true };
        }

        case fromClinics.SEARCH_CLINIC_SUCCESS: {
            const entities = action.payload;

            return { ...state, loading: false, loaded: true, entities };
        }

        case fromClinics.SEARCH_CLINIC_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        case fromClinics.CREATE_CLINIC: {
            return { ...state, loading: true };
        }

        case fromClinics.CREATE_CLINIC_SUCCESS: {
            const clinic = action.payload;
            const entities = { ...state.entities, [clinic.uid]: clinic };

            return { ...state, loading: false, loaded: true, entities };
        }

        case fromClinics.DELETE_CLINICS: {
            return { ...state, loading: true };
        }

        case fromClinics.DELETE_CLINICS_SUCCESS: {
            return { ...state, loading: false, loaded: true };
        }

        case fromClinics.IMPORT_CLINICS: {
            return {
                ...state,
                loaded: false,
                importing: true,
                imported: false,
            };
        }

        case fromClinics.IMPORT_CLINICS_SUCCESS: {
            return { ...state, importing: false, imported: true };
        }

        case fromClinics.IMPORT_CLINICS_FAIL: {
            return {
                ...state,
                loaded: true,
                importing: false,
                imported: false,
            };
        }
    }

    return state;
}

export const getClinicsEntities = (state: ClinicState) => state.entities;
export const getClinicsLoading = (state: ClinicState) => state.loading;
export const getClinicsLoaded = (state: ClinicState) => state.loaded;
export const getTotalClinics = (state: ClinicState) => state.total;
export const getClinicsImporting = (state: ClinicState) => state.importing;
export const getClinicsImported = (state: ClinicState) => state.imported;

export const getAllClinicsEntities = (state: ClinicState) => state.allClinics;
export const getAllClinicsLoading = (state: ClinicState) => state.allLoading;
export const getAllClinicsLoaded = (state: ClinicState) => state.allLoaded;
