import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromRoles from '../reducers/roles.reducer';

export const getRoleState = createSelector(
    fromFeature.getFilterState,
    (state: fromFeature.DashboardState) => state.roles
);

export const getRolesEntities = createSelector(
    getRoleState,
    fromRoles.getRolesEntities
);
export const getTotalRoles = createSelector(
    getRoleState,
    fromRoles.getTotalRoles
);
export const getPaginatedRoles = createSelector(
    getRolesEntities,
    entities => {
        return Object.keys(entities).map(uid => entities[uid]);
    }
);
export const getRolesLoaded = createSelector(
    getRoleState,
    fromRoles.getRolesLoaded
);
export const getRolesLoading = createSelector(
    getRoleState,
    fromRoles.getRolesLoading
);

/**
 * All Roles
 */
export const getAllRolesEntities = createSelector(
    getRoleState,
    fromRoles.getAllRolesEntities
);
export const getAllRoles = createSelector(
    getAllRolesEntities,
    allRoles => {
        return Object.keys(allRoles).map(uid => allRoles[uid]);
    }
);
export const getAllRolesLoaded = createSelector(
    getRoleState,
    fromRoles.getAllRolesLoaded
);
export const getAllRolesLoading = createSelector(
    getRoleState,
    fromRoles.getAllRolesLoading
);

/**
 * Importing roles state
 */
export const getRolesImporting = createSelector(
    getRoleState,
    fromRoles.getRolesImporting
);
export const getRolesImported = createSelector(
    getRoleState,
    fromRoles.getRolesImported
);
