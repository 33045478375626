import { Action } from '@ngrx/store';

/**
 * Create Roles
 */

export const CREATE_ROLE = '[Dashboard] Create Role';
export const CREATE_ROLE_SUCCESS = '[Dashboard] Create Role Success';
export const CREATE_ROLE_FAIL = '[Dashboard] Create Role Fail';

export class CreateRole implements Action {
    readonly type = CREATE_ROLE;
    constructor(public payload: any) { }
}
export class CreateRoleSuccess implements Action {
    readonly type = CREATE_ROLE_SUCCESS;
    constructor(public payload: any) { }
}
export class CreateRoleFail implements Action {
    readonly type = CREATE_ROLE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Import Roles
*/

export const IMPORT_ROLES = '[Dashboard] Import Roles';
export const IMPORT_ROLES_SUCCESS = '[Dashboard] Import Roles Success';
export const IMPORT_ROLES_FAIL = '[Dashboard] Import Roles Fail';

export class ImportRoles implements Action {
    readonly type = IMPORT_ROLES;
    constructor(public payload: any) { }
}
export class ImportRolesSuccess implements Action {
    readonly type = IMPORT_ROLES_SUCCESS;
    constructor() { }
}
export class ImportRolesFail implements Action {
    readonly type = IMPORT_ROLES_FAIL;
    constructor(public payload: any) { }
}

/**
 * Delete Roles
*/

export const DELETE_ROLE = '[Dashboard] Delete Role';
export const DELETE_ROLE_SUCCESS = '[Dashboard] Delete Role Success';
export const DELETE_ROLE_FAIL = '[Dashboard] Delete Role Fail';

export class DeleteRole implements Action {
    readonly type = DELETE_ROLE;
    constructor(public payload: any) { }
}
export class DeleteRoleSuccess implements Action {
    readonly type = DELETE_ROLE_SUCCESS;
    constructor(public payload: any) { }
}
export class DeleteRoleFail implements Action {
    readonly type = DELETE_ROLE_FAIL;
    constructor(public payload: any) { }
}


/**
 * Load All Roles
 */

export const LOAD_ALL_ROLES = '[Dashboard] Load All Roles';
export const LOAD_ALL_ROLES_SUCCESS = '[Dashboard] Load All Roles Success';
export const LOAD_ALL_ROLES_FAIL = '[Dashboard] Load All Roles Fail';

export class LoadAllRoles implements Action {
    readonly type = LOAD_ALL_ROLES;
}

export class LoadAllRolesSuccess implements Action {
    readonly type = LOAD_ALL_ROLES_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadAllRolesFail implements Action {
    readonly type = LOAD_ALL_ROLES_FAIL;
    constructor(public payload: any) { }
}

/**
 * Load Roles
 */

export const LOAD_ROLES = '[Dashboard] Load Roles';
export const LOAD_ROLES_SUCCESS = '[Dashboard] Load Roles Success';
export const LOAD_ROLES_FAIL = '[Dashboard] Load Roles Fail';

export class LoadRoles implements Action {
    readonly type = LOAD_ROLES;
}

export class LoadRolesSuccess implements Action {
    readonly type = LOAD_ROLES_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadRolesFail implements Action {
    readonly type = LOAD_ROLES_FAIL;
    constructor(public payload: any) { }
}

/**
 * Load More Roles
 */
export const LOAD_MORE_ROLES = '[Dashboard] Load More Roles';
export const LOAD_MORE_ROLES_SUCCESS = '[Dashboard] Load More Roles Success';
export const LOAD_MORE_ROLES_FAIL = '[Dashboard] Load More Roles Fail';

export class LoadMoreRoles implements Action {
    readonly type = LOAD_MORE_ROLES;
    constructor(public payload: any) { }
}

export class LoadMoreRolesSuccess implements Action {
    readonly type = LOAD_MORE_ROLES_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadMoreRolesFail implements Action {
    readonly type = LOAD_MORE_ROLES_FAIL;
    constructor(public payload: any) { }
}

/**
 * Search Roles
 */
export const SEARCH_ROLES = '[Dashboard] Search Roles';
export const SEARCH_ROLES_SUCCESS = '[Dashboard] Search Roles Success';
export const SEARCH_ROLES_FAIL = '[Dashboard] Search Roles Fail';

export class SearchRoles implements Action {
    readonly type = SEARCH_ROLES;
    constructor(public payload: any) { }
}

export class SearchRolesSuccess implements Action {
    readonly type = SEARCH_ROLES_SUCCESS;
    constructor(public payload: any) { }
}
export class SearchRolesFail implements Action {
    readonly type = SEARCH_ROLES_FAIL;
    constructor(public payload: any) { }
}

export type RoleAction =
    | ImportRoles
    | ImportRolesSuccess
    | ImportRolesFail
    | CreateRole
    | CreateRoleSuccess
    | CreateRoleFail
    | DeleteRole
    | DeleteRoleSuccess
    | DeleteRoleFail
    | LoadAllRoles
    | LoadAllRolesSuccess
    | LoadAllRolesFail
    | LoadRoles
    | LoadRolesSuccess
    | LoadRolesFail
    | LoadMoreRoles
    | LoadMoreRolesSuccess
    | LoadMoreRolesFail
    | SearchRoles
    | SearchRolesSuccess
    | SearchRolesFail;
