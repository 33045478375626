import { Action } from '@ngrx/store';

export const LOAD_ACCOUNTS_PERMISSIONS = '[Dashboard] Load Accounts Permissions';
export const LOAD_ACCOUNTS_PERMISSIONS_SUCCESS = '[Dashboard] Load Accounts Permissions Success';
export const LOAD_ACCOUNTS_PERMISSIONS_FAIL = '[Dashboard] Load Accounts Permissions Fail';

export const LOAD_UNITS_PERMISSIONS = '[Dashboard] Load Units Permissions';
export const LOAD_UNITS_PERMISSIONS_SUCCESS = '[Dashboard] Load Units Permissions Success';
export const LOAD_UNITS_PERMISSIONS_FAIL = '[Dashboard] Load Units Permissions Fail';

export class LoadAccountsPermissions implements Action {
    readonly type = LOAD_ACCOUNTS_PERMISSIONS;
    constructor(public payload: any) {}
}

export class LoadAccountsPermissionsSuccess implements Action {
    readonly type = LOAD_ACCOUNTS_PERMISSIONS_SUCCESS;
    constructor(public payload: any) {}
}

export class LoadAccountsPermissionsFail implements Action {
    readonly type = LOAD_ACCOUNTS_PERMISSIONS_FAIL;
    constructor(public payload: any) {}
}

export class LoadUnitsPermissions implements Action {
    readonly type = LOAD_UNITS_PERMISSIONS;
    constructor(public payload: any) {}
}

export class LoadUnitsPermissionsSuccess implements Action {
    readonly type = LOAD_UNITS_PERMISSIONS_SUCCESS;
    constructor(public payload: any) {}
}

export class LoadUnitsPermissionsFail implements Action {
    readonly type = LOAD_UNITS_PERMISSIONS_FAIL;
    constructor(public payload: any) {}
}

export type PermissionAction =
    | LoadAccountsPermissions
    | LoadAccountsPermissionsSuccess
    | LoadAccountsPermissionsFail
    | LoadUnitsPermissions
    | LoadUnitsPermissionsSuccess
    | LoadUnitsPermissionsFail;
