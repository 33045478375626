import { RoleGuard } from '@app/modules/admission/_guards/roles.guards';
import { DepartmentGuard } from '@app/modules/admission/_guards/departments.guards';
import { UnitGuard } from '@app/modules/admission/_guards/units.guards';
import { OrganizationGuard } from '@app/modules/admission/_guards/organizations.guards';
import { ClinicGuard } from '@app/modules/admission/_guards/clinics.guards';
import { PermissionAccountsGuard } from '@app/modules/admission/_guards/permissions-accounts.guard';
import { PermissionUnitsGuard } from '@app/modules/admission/_guards/permissions-units.guard';
import { ModulesGuard } from '@app/modules/admission/_guards/modules.guards';
import { PreferenceGuard } from '@app/modules/admission/_guards/preferences.guards';
import { CollaboratorsGuard } from '@app/modules/admission/_guards/collaborators.guards';

export const guards: any[] = [
    RoleGuard,
    DepartmentGuard,
    UnitGuard,
    OrganizationGuard,
    ClinicGuard,
    PermissionAccountsGuard,
    PermissionUnitsGuard,
    ModulesGuard,
    PreferenceGuard,
    CollaboratorsGuard
];

export * from '@app/modules/admission/_guards/roles.guards';
export * from '@app/modules/admission/_guards/departments.guards';
export * from '@app/modules/admission/_guards/units.guards';
export * from '@app/modules/admission/_guards/organizations.guards';
export * from '@app/modules/admission/_guards/clinics.guards';
export * from '@app/modules/admission/_guards/permissions-accounts.guard';
export * from '@app/modules/admission/_guards/permissions-units.guard';
export * from '@app/modules/admission/_guards/modules.guards';
export * from '@app/modules/admission/_guards/preferences.guards';
export * from '@app/modules/admission/_guards/collaborators.guards';
