import * as fromPermissions from '../actions/permissions.action';

export interface PermissionState {
    accounts: any;
    units: any;
    loadedAccounts: boolean;
    loadedUnits: boolean;
    loadingAccounts: boolean;
    loadingUnits: boolean;
}

export const initialState: PermissionState = {
    accounts: {},
    units: {},
    loadedAccounts: false,
    loadedUnits: false,
    loadingAccounts: false,
    loadingUnits: false
};

export function reducer(
    state = initialState,
    action: fromPermissions.PermissionAction
): PermissionState {
    switch (action.type) {
        case fromPermissions.LOAD_ACCOUNTS_PERMISSIONS: {
            return { ...state, loadingAccounts: true };
        }

        case fromPermissions.LOAD_ACCOUNTS_PERMISSIONS_SUCCESS: {
            const accounts = action.payload;
            return { ...state, loadingAccounts: false, loadedAccounts: true, accounts };
        }

        case fromPermissions.LOAD_ACCOUNTS_PERMISSIONS_FAIL: {
            return {
                ...state,
                loadingAccounts: false,
                loadedAccounts: false
            };
        }
        case fromPermissions.LOAD_UNITS_PERMISSIONS: {
            return { ...state, loadingUnits: true };
        }

        case fromPermissions.LOAD_UNITS_PERMISSIONS_SUCCESS: {
            const units = action.payload;
            return { ...state, loadingUnits: false, loadedUnits: true, units };
        }

        case fromPermissions.LOAD_UNITS_PERMISSIONS_FAIL: {
            return {
                ...state,
                loadingUnits: false,
                loadedUnits: false
            };
        }
    }

    return state;
}

export const getPermissionsAccountsEntities = (state: PermissionState) => state.accounts;
export const getPermissionsUnitsEntities = (state: PermissionState) => state.units;
export const getPermissionsAccountsLoading = (state: PermissionState) => state.loadingAccounts;
export const getPermissionsUnitsLoading = (state: PermissionState) => state.loadingUnits;
export const getPermissionsAccountsLoaded = (state: PermissionState) => state.loadedAccounts;
export const getPermissionsUnitsLoaded = (state: PermissionState) => state.loadedUnits;
