import * as fromDepartments from '../actions/departments.action';

import { Department } from '@app/core/_shared/models/departments.model';

export interface DepartmentState {
    entities: Array<Department>;
    allDepartments: Array<Department>;
    loaded: boolean;
    loading: boolean;
    allLoaded: boolean;
    allLoading: boolean;
    total: Number;
    importing: boolean;
    imported: boolean;
}

export const initialState: DepartmentState = {
    entities: [],
    allDepartments: [],
    loaded: false,
    loading: false,
    allLoaded: false,
    allLoading: false,
    total: 0,
    importing: false,
    imported: false
};

export function reducer(
    state = initialState,
    action: fromDepartments.DepartmentAction
): DepartmentState {
    switch (action.type) {
        case fromDepartments.LOAD_DEPARTMENTS: {
            return { ...state, loading: true };
        }

        case fromDepartments.LOAD_DEPARTMENTS_SUCCESS: {
            const departments = action.payload;
            const total = departments.total;
            const entities = departments.results.reduce(
                (all: { [uid: string]: Department }, item: Department) => {
                    return { ...all, [item.uid]: item };
                },
                {}
            );
            return { ...state, loading: false, loaded: true, entities, total };
        }

        case fromDepartments.LOAD_DEPARTMENTS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false
            };
        }

        case fromDepartments.LOAD_MORE_DEPARTMENTS: {
            return { ...state, loading: true };
        }

        case fromDepartments.LOAD_MORE_DEPARTMENTS_SUCCESS: {
            const departments = action.payload;
            const total = departments.total;

            const entities = departments.results.reduce(
                (all: { [uid: string]: Department }, item: Department) => {
                    return { ...all, [item.uid]: item };
                },
                {}
            );

            return { ...state, loading: false, loaded: true, entities, total };
        }

        case fromDepartments.LOAD_MORE_DEPARTMENTS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false
            };
        }

        case fromDepartments.LOAD_ALL_DEPARTMENTS: {
            return { ...state, allLoading: true };
        }

        case fromDepartments.LOAD_ALL_DEPARTMENTS_SUCCESS: {
            const allDepartments = action.payload;

            return { ...state, allLoading: false, allLoaded: true, allDepartments };
        }

        case fromDepartments.LOAD_ALL_DEPARTMENTS_FAIL: {
            return {
                ...state,
                allLoading: false,
                allLoaded: false
            };
        }

        case fromDepartments.SEARCH_DEPARTMENTS: {
            return { ...state, loading: true };
        }

        case fromDepartments.SEARCH_DEPARTMENTS_SUCCESS: {
            const entities = action.payload;

            return { ...state, loading: false, loaded: true, entities };
        }

        case fromDepartments.SEARCH_DEPARTMENTS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false
            };
        }

        case fromDepartments.CREATE_DEPARTMENTS: {
            return { ...state, loading: true }
        }

        case fromDepartments.CREATE_DEPARTMENTS_SUCCESS: {
            const department = action.payload;
            const entities = { ...state.entities, [department.uid]: department };
            return { ...state, loading: false, loaded: true, entities };
        }

        case fromDepartments.DELETE_DEPARTMENTS: {
            return { ...state, loading: true };
        }

        case fromDepartments.DELETE_DEPARTMENTS_SUCCESS: {
            return { ...state, loading: false, loaded: true };
        }

        case fromDepartments.IMPORT_DEPARTMENTS: {
            return { ...state, loaded: false, importing: true, imported: false };
        }

        case fromDepartments.IMPORT_DEPARTMENTS_SUCCESS: {
            return { ...state, importing: false, imported: true };
        }

        case fromDepartments.IMPORT_DEPARTMENTS_FAIL: {
            return { ...state, loaded: true, importing: false, imported: false };
        }
    }

    return state;
}

export const getDepartmentsEntities = (state: DepartmentState) => state.entities;
export const getDepartmentsLoading = (state: DepartmentState) => state.loading;
export const getDepartmentsLoaded = (state: DepartmentState) => state.loaded;
export const getTotalDepartments = (state: DepartmentState) => state.total;
export const getDepartmentsImporting = (state: DepartmentState) => state.importing;
export const getDepartmentsImported = (state: DepartmentState) => state.imported;

export const getAllDepartmentsEntities = (state: DepartmentState) => state.allDepartments;
export const getAllDepartmentsLoading = (state: DepartmentState) => state.allLoading;
export const getAllDepartmentsLoaded = (state: DepartmentState) => state.allLoaded;
