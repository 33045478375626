import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import * as Sentry from "@sentry/angular-ivy";
import { SegmentNameEnum, SegmentTitlePage } from './core/_shared/segment/segment.enum';
import { TrackingSegmentService } from './core/_shared/services/segment/tracking-segment.service';

declare let ga: any;
declare const Conpass;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@Sentry.TraceClassDecorator()
export class AppComponent implements OnInit {
    private subject = new Subject();
    public events = this.subject.asObservable();
    private name: string;
    private properties: any;
    private listIgnore = ['callback', 'preauthorize', '/models/view'];

    constructor(public router: Router, private tracking: TrackingSegmentService, private elementRef: ElementRef) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (!this.listIgnore.some(url => event.urlAfterRedirects.includes(url))) {
                    this.mountPage(event.urlAfterRedirects)
                    if (this.name && this.properties?.title) {
                        this.tracking.page(this.name, this.properties);
                    }
                }
            }
        });
    }

    ngOnInit(): void {
        this.elementRef.nativeElement.removeAttribute('ng-version');
    }

    onActivate(e, outlet) {
        outlet.scrollTop = 0;
    }

    mountPage(url: string): void {
        this.name = SegmentNameEnum[url],
        this.properties = {
            title: SegmentTitlePage[url],
            url: window.location.origin + url,
        }
    }
}
