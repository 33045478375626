import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
declare let environment: any;
@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
    @Input() companies: any;
    @Input() acc: string;
    @Output() change = new EventEmitter<any>();
    url: string = environment.cdn_url;
    constructor() {}

    ngOnInit() {}

    changeCompany(event) {
        this.change.emit(event);
    }
}
