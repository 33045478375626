import { Action } from '@ngrx/store';

/**
 * Load Preferences
 */
export const LOAD_PREFERENCES = '[Dashboard] Load Preferences';
export const LOAD_PREFERENCES_SUCCESS = '[Dashboard] Load Preferences Success';
export const LOAD_PREFERENCES_FAIL = '[Dashboard] Load Preferences Fail';

export class LoadPreferences implements Action {
    readonly type = LOAD_PREFERENCES;
}

export class LoadPreferencesSuccess implements Action {
    readonly type = LOAD_PREFERENCES_SUCCESS;
    constructor(public payload: any) {}
}

export class LoadPreferencesFail implements Action {
    readonly type = LOAD_PREFERENCES_FAIL;
    constructor(public payload: any) {}
}

/**
 * Update Preferences
 */
export const UPDATE_PREFERENCES = '[Dashboard] Update Preferences';
export const UPDATE_PREFERENCES_SUCCESS = '[Dashboard] Update Preferences Success';
export const UPDATE_PREFERENCES_FAIL = '[Dashboard] Update Preferences Fail';

export class UpdatePreferences implements Action {
    readonly type = UPDATE_PREFERENCES;
    constructor(public payload: any) {}
}

export class UpdatePreferencesSuccess implements Action {
    readonly type = UPDATE_PREFERENCES_SUCCESS;
    constructor(public payload: any) {}
}

export class UpdatePreferencesFail implements Action {
    readonly type = UPDATE_PREFERENCES_FAIL;
    constructor(public payload: any) {}
}

export type PreferenceAction =
    | LoadPreferences
    | LoadPreferencesSuccess
    | LoadPreferencesFail
    | UpdatePreferences
    | UpdatePreferencesSuccess
    | UpdatePreferencesFail;
