import { Action } from '@ngrx/store';

/**
 * Create Departments
 */
export const CREATE_DEPARTMENTS = '[Dashboard] Create Departments';
export const CREATE_DEPARTMENTS_SUCCESS = '[Dashboard] Create Departments Success';
export const CREATE_DEPARTMENTS_FAIL = '[Dashboard] Create Departments Fail';

export class CreateDepartments implements Action {
    readonly type = CREATE_DEPARTMENTS;
    constructor(public payload: any) {}
}

export class CreateDepartmentsSuccess implements Action {
    readonly type = CREATE_DEPARTMENTS_SUCCESS;
    constructor(public payload: any) {}
}

export class CreateDepartmentsFail implements Action {
    readonly type = CREATE_DEPARTMENTS_FAIL;
    constructor(public payload: any) {}
}

/**
 * Load All Departments
 */
export const LOAD_ALL_DEPARTMENTS = '[Dashboard] Load All Departments';
export const LOAD_ALL_DEPARTMENTS_SUCCESS = '[Dashboard] Load All Departments Success';
export const LOAD_ALL_DEPARTMENTS_FAIL = '[Dashboard] Load All Departments Fail';

export class LoadAllDepartments implements Action {
    readonly type = LOAD_ALL_DEPARTMENTS;
}

export class LoadAllDepartmentsSuccess implements Action {
    readonly type = LOAD_ALL_DEPARTMENTS_SUCCESS;
    constructor(public payload: any) {}
}

export class LoadAllDepartmentsFail implements Action {
    readonly type = LOAD_ALL_DEPARTMENTS_FAIL;
    constructor(public payload: any) {}
}

/**
 * Load Departments
 */
export const LOAD_DEPARTMENTS = '[Dashboard] Load Departments';
export const LOAD_DEPARTMENTS_SUCCESS = '[Dashboard] Load Departments Success';
export const LOAD_DEPARTMENTS_FAIL = '[Dashboard] Load Departments Fail';

export class LoadDepartments implements Action {
    readonly type = LOAD_DEPARTMENTS;
}

export class LoadDepartmentsSuccess implements Action {
    readonly type = LOAD_DEPARTMENTS_SUCCESS;
    constructor(public payload: any) {}
}

export class LoadDepartmentsFail implements Action {
    readonly type = LOAD_DEPARTMENTS_FAIL;
    constructor(public payload: any) {}
}

/**
 * Load More Departments
 */
export const LOAD_MORE_DEPARTMENTS = '[Dashboard] Load More Departments';
export const LOAD_MORE_DEPARTMENTS_SUCCESS = '[Dashboard] Load More Departments Success';
export const LOAD_MORE_DEPARTMENTS_FAIL = '[Dashboard] Load More Departments Fail';

export class LoadMoreDepartments implements Action {
    readonly type = LOAD_MORE_DEPARTMENTS;
    constructor(public payload: any) {}
}

export class LoadMoreDepartmentsSuccess implements Action {
    readonly type = LOAD_MORE_DEPARTMENTS_SUCCESS;
    constructor(public payload: any) {}
}

export class LoadMoreDepartmentsFail implements Action {
    readonly type = LOAD_MORE_DEPARTMENTS_FAIL;
    constructor(public payload: any) {}
}

/**
 * Search Departments
 */

export const SEARCH_DEPARTMENTS = '[Dashboard] Search Departments';
export const SEARCH_DEPARTMENTS_SUCCESS = '[Dashboard] Search Departments Success';
export const SEARCH_DEPARTMENTS_FAIL = '[Dashboard] Search Departments Fail';

export class SearchDepartments implements Action {
    readonly type = SEARCH_DEPARTMENTS;
    constructor(public payload: any) {}
}

export class SearchDepartmentsSuccess implements Action {
    readonly type = SEARCH_DEPARTMENTS_SUCCESS;
    constructor(public payload: any) {}
}

export class SearchDepartmentsFail implements Action {
    readonly type = SEARCH_DEPARTMENTS_FAIL;
    constructor(public payload: any) {}
}


/**
 * Delete Departments
 */

export const DELETE_DEPARTMENTS = '[Dashboard] Delete Departments';
export const DELETE_DEPARTMENTS_SUCCESS = '[Dashboard] Delete Departments Success';
export const DELETE_DEPARTMENTS_FAIL = '[Dashboard] Delete Departments Fail';

export class DeleteDepartments implements Action {
    readonly type = DELETE_DEPARTMENTS;
    constructor(public payload: any) { }
}

export class DeleteDepartmentsSuccess implements Action {
    readonly type = DELETE_DEPARTMENTS_SUCCESS;
    constructor(public payload: any) { }
}

export class DeleteDepartmentsFail implements Action {
    readonly type = DELETE_DEPARTMENTS_FAIL;
    constructor(public payload: any) { }
}

/**
 * Import Departments
*/

export const IMPORT_DEPARTMENTS = '[Dashboard] Import Departments';
export const IMPORT_DEPARTMENTS_SUCCESS = '[Dashboard] Import Departments Success';
export const IMPORT_DEPARTMENTS_FAIL = '[Dashboard] Import Departments Fail';

export class ImportDepartments implements Action {
    readonly type = IMPORT_DEPARTMENTS;
    constructor(public payload: any) { }
}
export class ImportDepartmentsSuccess implements Action {
    readonly type = IMPORT_DEPARTMENTS_SUCCESS;
    constructor() { }
}
export class ImportDepartmentsFail implements Action {
    readonly type = IMPORT_DEPARTMENTS_FAIL;
    constructor(public payload: any) { }
}

export type DepartmentAction =
    | CreateDepartments
    | CreateDepartmentsSuccess
    | CreateDepartmentsFail
    | LoadAllDepartments
    | LoadAllDepartmentsSuccess
    | LoadAllDepartmentsFail
    | LoadDepartments
    | LoadDepartmentsSuccess
    | LoadDepartmentsFail
    | LoadMoreDepartments
    | LoadMoreDepartmentsSuccess
    | LoadMoreDepartmentsFail
    | SearchDepartments
    | SearchDepartmentsSuccess
    | SearchDepartmentsFail
    | DeleteDepartments
    | DeleteDepartmentsSuccess
    | DeleteDepartmentsFail
    | ImportDepartments
    | ImportDepartmentsSuccess
    | ImportDepartmentsFail;
