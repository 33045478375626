import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as roleActions from '@app/modules/admission/_store/actions/roles.action';
import * as fromServices from '@app/core/_shared/services/roles/roles.service';

import * as fromStore from '../index';
import * as fromActions from '../actions';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable()
export class RoleEffects {
    constructor(
        private actions$: Actions,
        private roleService: fromServices.RolesService,
        private store: Store<fromStore.DashboardState>
    ) { }

    @Effect()
    roles$ = this.actions$.pipe(
        ofType(roleActions.LOAD_ROLES),
        switchMap((res: roleActions.LoadRoles) => {
            return this.roleService.read().pipe(
                map(
                    role =>
                        new roleActions.LoadRolesSuccess(role.result)
                ),
                catchError(error => of(new roleActions.LoadRolesFail(error)))
            );
        })
    );

    @Effect()
    loadMoreRoles$ = this.actions$.pipe(
        ofType(roleActions.LOAD_MORE_ROLES),
        switchMap((res: roleActions.LoadMoreRoles) => {
            return this.roleService.loadMore(res.payload.skip, res.payload.limit).pipe(
                map(
                    role =>
                        new roleActions.LoadMoreRolesSuccess(
                            role.result
                        )
                ),
                catchError(error =>
                    of(new roleActions.LoadMoreRolesFail(error))
                )
            );
        })
    );

    @Effect()
    loadAllRoles$ = this.actions$.pipe(
        ofType(roleActions.LOAD_ALL_ROLES),
        switchMap((res: roleActions.LoadAllRoles) => {
            return this.roleService.loadAll().pipe(
                map(
                    role =>
                        new roleActions.LoadAllRolesSuccess(role.result.results)
                ),
                catchError(error => of(new roleActions.LoadAllRolesFail(error)))
            );
        })
    );

    @Effect()
    searchRoles$ = this.actions$.pipe(
        ofType(roleActions.SEARCH_ROLES),
        switchMap((res: roleActions.SearchRoles) => {
            return this.roleService.search(res.payload).pipe(
                map(
                    role =>
                        new roleActions.SearchRolesSuccess(role.result.results)
                ),
                catchError(error => of(new roleActions.SearchRolesFail(error)))
            );
        })
    );

    @Effect()
    createRole$ = this.actions$.pipe(
        ofType(roleActions.CREATE_ROLE),
        switchMap((res: roleActions.CreateRole) => {
            return this.roleService.create(res.payload).pipe(
                map(role => new roleActions.CreateRoleSuccess(role.result)),
                catchError(error => of(new roleActions.CreateRoleFail(error)))
            );
        })
    );

    @Effect()
    deleteRole$ = this.actions$.pipe(
        ofType(roleActions.DELETE_ROLE),
        switchMap((res: roleActions.DeleteRole) => {
            return this.roleService.delete(res.payload.ids).pipe(
                map(() => new roleActions.DeleteRoleSuccess(res.payload)),
                catchError(error => of(new roleActions.DeleteRoleFail(error)))
            );
        })
    );

    @Effect({ dispatch: false })
    deleteSuccess$ = this.actions$.pipe(
        ofType(roleActions.DELETE_ROLE_SUCCESS),
        tap((res: any) => this.store.dispatch(new fromActions.LoadMoreRoles({ skip: res.payload.skip, limit: res.payload.limit })))
    );

    @Effect()
    importRole$ = this.actions$.pipe(
        ofType(roleActions.IMPORT_ROLES),
        switchMap((res: roleActions.ImportRoles) => {
            return this.roleService.import(res.payload).pipe(
                map(() => new roleActions.ImportRolesSuccess()),
                catchError(err => of(new roleActions.ImportRolesFail(err)))
            )
        })
    );

    @Effect({ dispatch: false })
    importSuccess$ = this.actions$.pipe(
        ofType(roleActions.IMPORT_ROLES_SUCCESS),
        tap(() => this.store.dispatch(new fromActions.LoadRoles()))
    );

    @Effect({ dispatch: false })
    importFail$ = this.actions$.pipe(
        ofType(roleActions.IMPORT_ROLES_FAIL),
        tap(res => res)
    );
}
