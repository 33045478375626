<app-header (notify)="onNotify($event)" [menu]="isOpen"></app-header>
<app-sidebar-account (menu)="onNotify($event)" [openMenu]="isOpen"></app-sidebar-account>
<main class="dashboard-container">
    <div class="dashboard-content">
        <router-outlet></router-outlet>
    </div>
</main>
<footer class="dashboard-footer">
    <p>© {{year}} Unico People</p>
</footer>
