import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { switchMap, map, catchError, retry } from 'rxjs/operators';
import { of } from 'rxjs';

import * as moduleActions from '../actions/modules.action';
import * as fromServices from '@app/core/_shared/services/modules/modules.service';

@Injectable()
export class ModuleEffects {
    constructor(
        private actions$: Actions,
        private moduleService: fromServices.ModulesService
    ) {}

    @Effect()
    modules$ = this.actions$.pipe(
        ofType(moduleActions.LOAD_MODULES),
        switchMap((res: moduleActions.LoadModules) => {
            return this.moduleService.read(res.payload).pipe(
                map(module => new moduleActions.LoadModulesSuccess(module)),
                retry(2),
                catchError(error =>
                    of(new moduleActions.LoadModulesFail(error))
                )
            );
        })
    );
}
