import { Action } from '@ngrx/store';

/**
 * Create Clinics
 */
export const CREATE_CLINIC = '[Dashboard] Create Clinic';
export const CREATE_CLINIC_SUCCESS = '[Dashboard] Create Clinic Success';
export const CREATE_CLINIC_FAIL = '[Dashboard] Create Clinic Fail';

export class CreateClinic implements Action {
    readonly type = CREATE_CLINIC;
    constructor(public payload: any) { }
}

export class CreateClinicSuccess implements Action {
    readonly type = CREATE_CLINIC_SUCCESS;
    constructor(public payload: any) { }
}

export class CreateClinicFail implements Action {
    readonly type = CREATE_CLINIC_FAIL;
    constructor(public payload: any) { }
}

/**
 * Load All Clinics
 */
export const LOAD_ALL_CLINICS = '[Dashboard] Load All Clinics';
export const LOAD_ALL_CLINICS_SUCCESS = '[Dashboard] Load All Clinics Success';
export const LOAD_ALL_CLINICS_FAIL = '[Dashboard] Load All Clinics Fail';

export class LoadAllClinics implements Action {
    readonly type = LOAD_ALL_CLINICS;
}

export class LoadAllClinicsSuccess implements Action {
    readonly type = LOAD_ALL_CLINICS_SUCCESS;
    constructor(public payload: any) {}
}

export class LoadAllClinicsFail implements Action {
    readonly type = LOAD_ALL_CLINICS_FAIL;
    constructor(public payload: any) {}
}


/**
 * Load Clinics
 */
export const LOAD_CLINICS = '[Dashboard] Load Clinics';
export const LOAD_CLINICS_SUCCESS = '[Dashboard] Load Clinics Success';
export const LOAD_CLINICS_FAIL = '[Dashboard] Load Clinics Fail';

export class LoadClinics implements Action {
    readonly type = LOAD_CLINICS;
}

export class LoadClinicsSuccess implements Action {
    readonly type = LOAD_CLINICS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadClinicsFail implements Action {
    readonly type = LOAD_CLINICS_FAIL;
    constructor(public payload: any) { }
}

/**
 * Load More Clinics
 */
export const LOAD_MORE_CLINICS = '[Dashboard] Load More Clinics';
export const LOAD_MORE_CLINICS_SUCCESS = '[Dashboard] Load More Clinics Success';
export const LOAD_MORE_CLINICS_FAIL = '[Dashboard] Load More Clinics Fail';

export class LoadMoreClinics implements Action {
    readonly type = LOAD_MORE_CLINICS;
    constructor(public payload: any) { }
}

export class LoadMoreClinicsSuccess implements Action {
    readonly type = LOAD_MORE_CLINICS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadMoreClinicsFail implements Action {
    readonly type = LOAD_MORE_CLINICS_FAIL;
    constructor(public payload: any) { }
}

/**
 * Create Clinics
 */
export const SEARCH_CLINIC = '[Dashboard] Search Clinic';
export const SEARCH_CLINIC_SUCCESS = '[Dashboard] Search Clinic Success';
export const SEARCH_CLINIC_FAIL = '[Dashboard] Search Clinic Fail';

export class SearchClinic implements Action {
    readonly type = SEARCH_CLINIC;
    constructor(public payload: any) { }
}

export class SearchClinicSuccess implements Action {
    readonly type = SEARCH_CLINIC_SUCCESS;
    constructor(public payload: any) { }
}

export class SearchClinicFail implements Action {
    readonly type = SEARCH_CLINIC_FAIL;
    constructor(public payload: any) { }
}


/**
 * Delete Clinics
 */
export const DELETE_CLINICS = '[Dashboard] Delete Clinics';
export const DELETE_CLINICS_SUCCESS = '[Dashboard] Delete Clinics Success';
export const DELETE_CLINICS_FAIL = '[Dashboard] Delete Clinics Fail';

export class DeleteClinics implements Action {
    readonly type = DELETE_CLINICS;
    constructor(public payload: any) { }
}

export class DeleteClinicsSuccess implements Action {
    readonly type = DELETE_CLINICS_SUCCESS;
    constructor(public payload: any) { }
}

export class DeleteClinicsFail implements Action {
    readonly type = DELETE_CLINICS_FAIL;
    constructor(public payload: any) { }
}

/**
 * Import Clinics
*/

export const IMPORT_CLINICS = '[Dashboard] Import Clinics';
export const IMPORT_CLINICS_SUCCESS = '[Dashboard] Import Clinics Success';
export const IMPORT_CLINICS_FAIL = '[Dashboard] Import Clinics Fail';

export class ImportClinics implements Action {
    readonly type = IMPORT_CLINICS;
    constructor(public payload: any) { }
}
export class ImportClinicsSuccess implements Action {
    readonly type = IMPORT_CLINICS_SUCCESS;
    constructor() { }
}
export class ImportClinicsFail implements Action {
    readonly type = IMPORT_CLINICS_FAIL;
    constructor(public payload: any) { }
}

export type ClinicAction =
    | LoadClinics
    | LoadClinicsSuccess
    | LoadClinicsFail
    | LoadAllClinics
    | LoadAllClinicsSuccess
    | LoadAllClinicsFail
    | LoadMoreClinics
    | LoadMoreClinicsSuccess
    | LoadMoreClinicsFail
    | CreateClinic
    | CreateClinicSuccess
    | CreateClinicFail
    | SearchClinic
    | SearchClinicSuccess
    | SearchClinicFail
    | DeleteClinics
    | DeleteClinicsSuccess
    | DeleteClinicsFail
    | ImportClinics
    | ImportClinicsSuccess
    | ImportClinicsFail;
