import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromDashboardStore from '@app/modules/admission/_store/selectors';

import { Unit } from '@app/core/_shared/models/units.model';

declare let environment: any;

@Injectable({ providedIn: 'root' })
export class UnitsService {
    private url: string = environment.url;
    private acc: string;

    constructor(private http: HttpClient, private store: Store<any>) {
        this.store
            .pipe(select(fromDashboardStore.getOrganizationState))
            .subscribe(res => {
                this.acc = res.currentAccount.uid;
            });
    }

    /**
     * Load all units
     */
    loadAll(acc: string = this.acc): Observable<Unit[]> {
        return this.http.get<any>(`${this.url}/units?account=${acc}`);
    }

    /**
     * Load paginated units
     */
    read(acc: string = this.acc): Observable<Unit[]> {
        return this.http.get<any>(
            `${this.url}/units?account=${acc}&skip=0&limit=50`
        );
    }

    /**
     * Load more units
     */
    loadMore(skip: number): Observable<Unit[]> {
        return this.http.get<any>(
            `${this.url}/units?account=${this.acc}&skip=${skip}&limit=50`
        );
    }

    /**
     * Search roles
     * @param query term to search
     */
    search(query: string): Observable<Unit[]> {
        return this.http.get<any>(
            `${this.url}/search/units?account=${this.acc}&q=${query}`
        );
    }
}
