import { Injectable } from '@angular/core';
import * as clinicActions from '@app/modules/admission/_store/actions/clinics.action';
import * as fromServices from '@app/core/_shared/services/clinics/clinics.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import * as fromActions from '../actions';
import * as fromStore from '../index';

@Injectable()
export class ClinicsEffects {
    constructor(
        private actions$: Actions,
        private clinicService: fromServices.ClinicsService,
        private store: Store<fromStore.DashboardState>
    ) {}

    @Effect()
    loadClinics$ = this.actions$.pipe(
        ofType(clinicActions.LOAD_CLINICS),
        switchMap((res: clinicActions.LoadClinics) => {
            return this.clinicService.loadPaginated().pipe(
                map(
                    (clinics) =>
                        new clinicActions.LoadClinicsSuccess(clinics.result)
                ),
                catchError((error) =>
                    of(new clinicActions.LoadClinicsFail(error))
                )
            );
        })
    );

    @Effect()
    loadMoreClinics$ = this.actions$.pipe(
        ofType(clinicActions.LOAD_MORE_CLINICS),
        switchMap((res: clinicActions.LoadMoreClinics) => {
            return this.clinicService
                .loadMore(res.payload.skip, res.payload.limit)
                .pipe(
                    map(
                        (clinic) =>
                            new clinicActions.LoadMoreClinicsSuccess(
                                clinic.result
                            )
                    ),
                    catchError((error) =>
                        of(new clinicActions.LoadMoreClinicsFail(error))
                    )
                );
        })
    );

    @Effect()
    loadAllDepartments$ = this.actions$.pipe(
        ofType(clinicActions.LOAD_ALL_CLINICS),
        switchMap((res: clinicActions.LoadAllClinics) => {
            return this.clinicService.loadAll().pipe(
                map(
                    (clinic) =>
                        new clinicActions.LoadAllClinicsSuccess(
                            clinic.result.results
                        )
                ),
                catchError((error) =>
                    of(new clinicActions.LoadAllClinicsFail(error))
                )
            );
        })
    );

    @Effect()
    searchClinics$ = this.actions$.pipe(
        ofType(clinicActions.SEARCH_CLINIC),
        debounceTime(400),
        switchMap((res: clinicActions.SearchClinic) => {
            return this.clinicService.search(res.payload).pipe(
                map(
                    (clinic) =>
                        new clinicActions.SearchClinicSuccess(
                            clinic.result.results
                        )
                ),
                catchError((error) =>
                    of(new clinicActions.SearchClinicFail(error))
                )
            );
        })
    );

    @Effect()
    createClinic$ = this.actions$.pipe(
        ofType(clinicActions.CREATE_CLINIC),
        map((action: clinicActions.CreateClinic) => action.payload),
        switchMap((res) => {
            return this.clinicService.create(res).pipe(
                map(
                    (clinic) =>
                        new clinicActions.CreateClinicSuccess(clinic.result)
                ),
                catchError((error) =>
                    of(new clinicActions.CreateClinicSuccess(error))
                )
            );
        })
    );

    @Effect()
    deleteClinics$ = this.actions$.pipe(
        ofType(clinicActions.DELETE_CLINICS),
        switchMap((res: clinicActions.DeleteClinics) => {
            return this.clinicService.delete(res.payload.ids).pipe(
                map(() => new clinicActions.DeleteClinicsSuccess(res.payload)),
                catchError((error) =>
                    of(new clinicActions.DeleteClinicsFail(error))
                )
            );
        })
    );

    @Effect({ dispatch: false })
    deleteSuccess$ = this.actions$.pipe(
        ofType(clinicActions.DELETE_CLINICS_SUCCESS),
        tap((res: any) =>
            this.store.dispatch(
                new fromActions.LoadMoreClinics({
                    skip: res.payload.skip,
                    limit: res.payload.limit,
                })
            )
        )
    );

    @Effect()
    importClinics$ = this.actions$.pipe(
        ofType(clinicActions.IMPORT_CLINICS),
        switchMap((res: clinicActions.ImportClinics) => {
            return this.clinicService.import(res.payload).pipe(
                map(() => new clinicActions.ImportClinicsSuccess()),
                catchError((err) =>
                    of(new clinicActions.ImportClinicsFail(err))
                )
            );
        })
    );

    @Effect({ dispatch: false })
    importSuccess$ = this.actions$.pipe(
        ofType(clinicActions.IMPORT_CLINICS_SUCCESS),
        tap(() => this.store.dispatch(new fromActions.LoadClinics()))
    );

    @Effect({ dispatch: false })
    importFail$ = this.actions$.pipe(
        ofType(clinicActions.IMPORT_CLINICS_FAIL),
        tap((res) => res)
    );
}
