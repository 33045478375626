import * as fromModules from '@app/modules/admission/_store/actions/modules.action';

export interface ModuleState {
    entities: any;
    loaded: boolean;
    loading: boolean;
}

export const initialState: ModuleState = {
    entities: {},
    loaded: false,
    loading: false
};

export function reducer(
    state = initialState,
    action: fromModules.ModuleAction
): ModuleState {
    switch (action.type) {
        case fromModules.LOAD_MODULES: {
            return { ...state, loading: true };
        }

        case fromModules.LOAD_MODULES_SUCCESS: {
            const entities = action.payload;
            return { ...state, loading: false, loaded: true, entities };
        }

        case fromModules.LOAD_MODULES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false
            };
        }
    }

    return state;
}

export const getModulesEntities = (state: ModuleState) => state.entities;
export const getModulesLoading = (state: ModuleState) => state.loading;
export const getModulesLoaded = (state: ModuleState) => state.loaded;
