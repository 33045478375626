import { LaunchdarklyService } from '@acesso-io/launchdarkly-client-ts/lib/launchdarkly';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/_guards/auth/auth.service';
import { SegmentEventName } from '@app/core/_shared/segment/segment.enum';
import { TrackingSegmentService } from '@app/core/_shared/services/segment/tracking-segment.service';
import * as ld from 'launchdarkly-js-client-sdk';
import { timeout } from 'rxjs/operators';
import { CallbackService } from './callback.service';

declare const environment;
declare const userGuiding;

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  public startTime: number = 0;
  public endTime: number = 0;
  public enableCustomRedirect: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private callbackService: CallbackService,
    private trackingService: TrackingSegmentService,
    private authService: AuthService,
    private launchDarklyService: LaunchdarklyService
  ) {
    this.startTime = 0;
    this.endTime = 0;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.getToken(params.code);
    });

    this.enableCustomRedirect = this.launchDarklyService?.evaluateBoolean({flagKey: "people-rh-custom-redirect"});
  }

  getToken(code: string): void {
    this.startTime = performance.now() || 0;
    this.callbackService
      .getToken(code)
      .pipe(timeout(6000))
      .subscribe(
        (response) => {
          const { token } = response;
          localStorage.setItem('token', token);
          this.getAuthorization(token);
        },
        () => this.authService.logout()
      );
  }

  getAuthorization(token: string): void {
    this.callbackService
      .getAuthorization(token)
      .subscribe(
        async (response) => {
          this.endTime = performance.now() || 0;
          const csrf_token = response?.headers?.get('acesso-csrf-token');
          localStorage.setItem('csrf_token', csrf_token);

          const { name, email, id, organization, organizationName } = response?.body || {};

          localStorage.setItem(
            'user',
            JSON.stringify({
              user_name: name,
              user_email: email,
              user_id: id,
            })
          );

          this.trackingService.identify(id, { organization_name: organizationName });
          this.track(organization, organizationName);
          localStorage.setItem('organization', organization);
          localStorage.setItem('organizationName', organizationName);
          localStorage.setItem('originAppName', "dashboard");

          await this.identifyContextOnLaunchDarkly(organization);

          const redirectCustomUrl = sessionStorage.getItem('redirectUrl');
          if (this.enableCustomRedirect && redirectCustomUrl) {
            const redirectUrl = (redirectCustomUrl?.toString() === '') ?
              '/' : redirectCustomUrl?.toString();
            sessionStorage.removeItem('redirectUrl');
            this.router.navigate([redirectUrl]);
            return;
          }
          this.router.navigate(['/']);
        },
        () => this.authService.logout()
      );
  }

  track(organization: string, organizationName: string) {
    const time = Math.round(this.endTime - this.startTime);
    this.trackingService.track(SegmentEventName.authentication_time, {
      organization_name: organizationName,
      permission_time: time,
      organization: organization
    });
  }

  private identifyContextOnLaunchDarkly(organizationId: string) {
    const user = JSON.parse(localStorage.getItem('user'));

    const context = {
      kind: 'multi',
      organization: {
        key: organizationId,
      },
      user: {
        key: user.user_id,
        appName: 'dashboard',
      },
    } as ld.LDMultiKindContext;

    return this.launchDarklyService.overrideContext({ context });
  }
}
