import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromUnits from '../reducers/units.reducer';

export const getUnitState = createSelector(
    fromFeature.getFilterState,
    (state: fromFeature.DashboardState) => state.units
);

export const getUnitsEntities = createSelector(
    getUnitState,
    fromUnits.getUnitsEntities
);

export const getPaginatedUnits = createSelector(
    getUnitsEntities,
    entities => {
        return Object.keys(entities).map(uid => entities[uid]);
    }
);

export const getUnitsLoaded = createSelector(
    getUnitState,
    fromUnits.getUnitsLoaded
);
export const getUnitsLoading = createSelector(
    getUnitState,
    fromUnits.getUnitsLoading
);

export const getAllUnitsEntities = createSelector(
    getUnitState,
    fromUnits.getAllUnitsEntities
);

export const getAllUnits = createSelector(
    getAllUnitsEntities,
    allUnits => {
        return Object.keys(allUnits).map(uid => allUnits[uid]);
    }
);
export const getAllUnitsLoaded = createSelector(
    getUnitState,
    fromUnits.getAllUnitsLoaded
);
export const getAllUnitsLoading = createSelector(
    getUnitState,
    fromUnits.getAllUnitsLoading
);
