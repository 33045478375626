import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromModules from '../reducers/modules.reducer';

export const getModuleState = createSelector(
    fromFeature.getFilterState,
    (state: fromFeature.DashboardState) => state.modules
);

export const getModulesEntities = createSelector(
    getModuleState,
    fromModules.getModulesEntities
);

export const getAllModules = createSelector(getModulesEntities, entities => {
    return Object.keys(entities).map(uid => entities[uid]);
});

export const getModulesLoaded = createSelector(
    getModuleState,
    fromModules.getModulesLoaded
);
export const getModulesLoading = createSelector(
    getModuleState,
    fromModules.getModulesLoading
);
