import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromClinics from '../reducers/clinics.reducer';

export const getClinicState = createSelector(
    fromFeature.getFilterState,
    (state: fromFeature.DashboardState) => state.clinics
);

export const getClinicsEntities = createSelector(
    getClinicState,
    fromClinics.getClinicsEntities
);

export const getTotalClinics = createSelector(
    getClinicState,
    fromClinics.getTotalClinics
);

export const getPaginatedClinics = createSelector(
    getClinicsEntities,
    (entities) => {
        return Object.keys(entities).map((uid) => entities[uid]);
    }
);

export const getClinicsLoaded = createSelector(
    getClinicState,
    fromClinics.getClinicsLoaded
);
export const getClinicsLoading = createSelector(
    getClinicState,
    fromClinics.getClinicsLoading
);

/**
 * All Clinics
 */
export const getAllClinicsEntities = createSelector(
    getClinicState,
    fromClinics.getAllClinicsEntities
);
export const getAllClinics = createSelector(
    getAllClinicsEntities,
    allClinics => allClinics
);
export const getAllClinicsLoaded = createSelector(
    getClinicState,
    fromClinics.getAllClinicsLoaded
);
export const getAllClinicsLoading = createSelector(
    getClinicState,
    fromClinics.getAllClinicsLoading
);

/**
 * Importing clinics state
 */
export const getClinicsImporting = createSelector(
    getClinicState,
    fromClinics.getClinicsImporting
);
export const getClinicsImported = createSelector(
    getClinicState,
    fromClinics.getClinicsImported
);
