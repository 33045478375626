import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, Observable } from 'rxjs';
import { map, switchMap, catchError, tap, retry } from 'rxjs/operators';

import * as fromStore from '../index';
import * as fromActions from '../actions';
import * as fromServices from '@app/core/_shared/services/organizations/organizations.service';
import { TrackingSegmentService } from '@app/core/_shared/services/segment/tracking-segment.service';

@Injectable()
export class OrganizationEffects {
  constructor(
    private actions$: Actions,
    private organizationService: fromServices.OrganizationsService,
    private store: Store<fromStore.DashboardState>,
    private tracking: TrackingSegmentService
  ) {}

  @Effect()
  callback$: Observable<any> = this.actions$.pipe(
    ofType(fromActions.LOAD_ORGANIZATION),
    switchMap((res: fromActions.LoadOrganization) => {
      return this.organizationService.getOrganization(res.payload).pipe(
        map(
          callback =>
            new fromActions.LoadOrganizationSuccess({
              callback: callback,
              token: res.payload
            })
        ),
        retry(2),
        catchError(error => of(new fromActions.LoadOrganizationFail(error)))
      );
    })
  );

  @Effect({ dispatch: false })
  callbackSuccess$: Observable<any> = this.actions$.pipe(
    ofType(fromActions.LOAD_ORGANIZATION_SUCCESS),
    tap(res => {
      if (localStorage.getItem('currentAccount') === null) {
        localStorage.setItem('currentAccount', res.payload.callback[0].accounts[0].uid);
      }
      const groups = res.payload.callback;
      const currentAccount = groups
        .reduce((all, item) => {
          item.accounts.map(res => {
            all.push(res);
          });
          return all;
        }, [])
        .filter(x => x.uid === localStorage.getItem('currentAccount'))[0];

      localStorage.setItem('token', res.payload.token);
      this.tracking.account = currentAccount.name;
    })
  );

  @Effect({ dispatch: false })
  changeAccount$: Observable<any> = this.actions$.pipe(
    ofType(fromActions.CHANGE_ACCOUNT),
    tap(() => {
      this.store.dispatch(new fromActions.LoadDepartments());
      this.store.dispatch(new fromActions.LoadClinics());
      this.store.dispatch(new fromActions.LoadRoles());
      this.store.dispatch(new fromActions.LoadUnits());
      this.store.dispatch(new fromActions.LoadAccountsPermissions(localStorage.getItem('token')));
    })
  );
}
