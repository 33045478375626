import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header.component';
import { SearchModule } from '@app/layout/content-layout/components/search/search.module';

@NgModule({
    imports: [CommonModule, RouterModule, SearchModule],
    declarations: [HeaderComponent],
    exports: [HeaderComponent]
})
export class HeaderModule {}
