import * as fromPreferences from '@app/modules/admission/_store/actions/preferences.action';

export interface PreferenceState {
    entities: any;
    loaded: boolean;
    loading: boolean;
}

export const initialState: PreferenceState = {
    entities: {},
    loaded: false,
    loading: false,
};

export function reducer(
    state = initialState,
    action: fromPreferences.PreferenceAction
): PreferenceState {
    switch (action.type) {
        case fromPreferences.LOAD_PREFERENCES: {
            return { ...state, loading: true };
        }

        case fromPreferences.LOAD_PREFERENCES_SUCCESS: {
            const entities = action.payload;
            return { ...state, loading: false, loaded: true, entities };
        }

        case fromPreferences.LOAD_PREFERENCES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }

        case fromPreferences.UPDATE_PREFERENCES: {
            return { ...state, loading: true };
        }

        case fromPreferences.UPDATE_PREFERENCES_SUCCESS: {
            const entities = state.entities.map((e) => {
                if (e.accountID === action.payload.account) {
                    const preferences = e.preferences.map((p) => {
                        if (p._id === action.payload.notification._id) {
                            return {
                                ...p,
                                active: action.payload.notification.active,
                            };
                        }
                        return p;
                    });

                    e = {
                        ...e,
                        notification: action.payload.notification,
                        preferences,
                    };
                }
                return e;
            });
            return { ...state, loading: false, loaded: true, entities };
        }

        case fromPreferences.UPDATE_PREFERENCES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        }
    }

    return state;
}

export const getPreferencesEntities = (state: PreferenceState) =>
    state.entities;
export const getPreferencesLoading = (state: PreferenceState) => state.loading;
export const getPreferencesLoaded = (state: PreferenceState) => state.loaded;
