<header class="dashboard-header" id="main-header" (mouseleave)="offClickHandler()">
    <div class="header-top">
        <div>
            <button class="button-open-menu" data-testid="sidebar-button" aria-label="Abre menu lateral" id="open-sidebar-account" type="button" (click)="openMenu()">
                <span></span>
                <span></span>
                <span></span>
            </button>
            <div class="logo-acesso" data-testid="logo-box">
                <a [routerLink]="baseUrl"><img src="./assets/images/core/logo-unico-people.svg" alt="Logomarca unico | people"></a>
            </div>
            <p class="account-name" data-testid="company-name">{{currentAccount?.name}}</p>
        </div>
        <div class="notification-content" #container>
            <app-search></app-search>
            <div class="user-infos">
                <a href="https://empresas.unico.io" aria-label="Central de Ajuda" class="helper-link" rel="noopener" target="_blank"><img src="./assets/images/core/ico-help.svg" alt="Ícone da Central de Ajuda"></a>
                <span class="logo-user" data-testid="user-logo"><img src="./assets/images/core/user.svg" alt="Ícone de avatar do usuário"></span>
                <span class="username-header" data-testid="user-name" (click)="logoutBox = !logoutBox" role="button" aria-label="Abre o menu do usuário">{{user.user_name}}</span>
                <a (click)="logoutBox = !logoutBox" class="open-settings">open</a>
            </div>
            <ul id="logout" class="logout-box" data-testid="user-menu" [hidden]="logoutBox" #dropdown>
                <li data-testid="preferences-button"><a (click)="preferences()">Minhas Preferências</a></li>
                <li data-testid="logout-button" class="logout"><a (click)="logout()">Sair</a></li>
            </ul>
        </div>
    </div>
</header>
