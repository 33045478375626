<span class="detail-bar"></span>
<div class="maintenance-container">
    <div class="maintenance-description">
        <h1>AcessoRH está em manutenção</h1>
        <p>Estaremos de volta em alguns instantes.</p>
        <p>Obrigado pela sua paciência.</p>
        <img src="/assets/images/core/maintenance.svg" alt="Ilustração com funcionários fazendo manutenção em um dashboard.">
    </div>
</div>
<footer>
    <p>Desenvolvido com ❤️ por Acesso Digital.</p>
</footer>
