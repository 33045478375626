import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { switchMap, map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import * as preferencesActions from '../actions/preferences.action';
import * as fromServices from '@app/core/_shared/services/preferences/preferences.service';
import * as fromDashboardStore from '@app/modules/admission/_store';

@Injectable()
export class PreferencesEffects {
    constructor(
        private actions$: Actions,
        private preferencesService: fromServices.PreferencesService,
        private store: Store<fromDashboardStore.DashboardState>
    ) {}

    @Effect()
    preferences$ = this.actions$.pipe(
        ofType(preferencesActions.LOAD_PREFERENCES),
        switchMap((res: preferencesActions.LoadPreferences) => {
            const data = {
                user: JSON.parse(localStorage.getItem('user')).user_id,
                account: localStorage.getItem('currentAccount'),
                include: {
                    notifications: true
                }
            };
            return this.preferencesService.getPreferences(data).pipe(
                map(
                    p =>
                        new preferencesActions.LoadPreferencesSuccess(
                            p.result.preferences
                        )
                ),
                catchError(error =>
                    of(new preferencesActions.LoadPreferencesFail(error))
                )
            );
        })
    );

    @Effect()
    callbackUpdate$: Observable<any> = this.actions$.pipe(
        ofType(preferencesActions.UPDATE_PREFERENCES),
        switchMap((res: preferencesActions.UpdatePreferences) => {
            return this.preferencesService.updatePreference(res.payload).pipe(
                map(
                    p =>
                        new preferencesActions.UpdatePreferencesSuccess(
                            p.result.preference
                        )
                ),
                catchError(error =>
                    of(new preferencesActions.UpdatePreferencesFail(error))
                )
            );
        })
    );
}
