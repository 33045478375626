import * as fromRoles from '../actions/roles.action';

import { RoleData } from '@app/core/_shared/models/roles.model';

export interface RoleState {
    entities: Array<RoleData>;
    allRoles: Array<RoleData>;
    loaded: boolean;
    loading: boolean;
    allLoaded: boolean;
    allLoading: boolean;
    total: number;
    importing: boolean;
    imported: boolean;
}

export const initialState: RoleState = {
    entities: [],
    allRoles: [],
    loaded: false,
    loading: false,
    allLoaded: false,
    allLoading: false,
    total: 0,
    importing: false,
    imported: false
};

export function reducer(
    state = initialState,
    action: fromRoles.RoleAction
): RoleState {
    switch (action.type) {
        case fromRoles.LOAD_ROLES: {
            return { ...state, loading: true };
        }

        case fromRoles.LOAD_ROLES_SUCCESS: {
            const roles = action.payload;
            const total = roles.total;
            const entities = roles.results.reduce(
                (all: { [uid: string]: RoleData }, item: RoleData) => {
                    return { ...all, [item.uid]: item };
                },
                {}
            );

            return { ...state, loading: false, loaded: true, entities, total };
        }

        case fromRoles.LOAD_ROLES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false
            };
        }

        case fromRoles.LOAD_MORE_ROLES: {
            return { ...state, loading: true };
        }

        case fromRoles.LOAD_MORE_ROLES_SUCCESS: {
            const roles = action.payload;
            const total = roles.total;

            const entities = roles.results.reduce(
                (all: { [uid: string]: RoleData }, item: RoleData) => {
                    return { ...all, [item.uid]: item };
                },
                {}
            );

            return { ...state, loading: false, loaded: true, entities, total };
        }

        case fromRoles.LOAD_MORE_ROLES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false
            };
        }

        case fromRoles.LOAD_ALL_ROLES: {
            return { ...state, allLoading: true };
        }

        case fromRoles.LOAD_ALL_ROLES_SUCCESS: {
            const roles = action.payload;

            const allRoles = roles.reduce(
                (all: { [uid: string]: RoleData }, item: RoleData) => {
                    return { ...all, [item.uid]: item };
                },
                {}
            );

            return { ...state, allLoading: false, allLoaded: true, allRoles };
        }

        case fromRoles.LOAD_ALL_ROLES_FAIL: {
            return {
                ...state,
                allLoading: false,
                allLoaded: false
            };
        }

        case fromRoles.SEARCH_ROLES: {
            return { ...state, loading: true };
        }

        case fromRoles.SEARCH_ROLES_SUCCESS: {
            const roles = action.payload;

            const entities = roles.reduce(
                (all: { [uid: string]: RoleData }, item: RoleData) => {
                    return { ...all, [item.uid]: item };
                },
                {}
            );

            return { ...state, loading: false, loaded: true, entities };
        }

        case fromRoles.SEARCH_ROLES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false
            };
        }

        case fromRoles.CREATE_ROLE: {
            return { ...state, loading: true };
        }

        case fromRoles.CREATE_ROLE_SUCCESS: {
            const role = action.payload;
            const entities = { ...state.entities, [role.uid]: role };
            return { ...state, loading: false, loaded: true, entities };
        }

        case fromRoles.IMPORT_ROLES: {
            return { ...state, loaded: false, importing: true, imported: false };
        }

        case fromRoles.IMPORT_ROLES_SUCCESS: {
            return { ...state, importing: false, imported: true };
        }

        case fromRoles.IMPORT_ROLES_FAIL: {
            return { ...state, loaded: true, importing: false, imported: false };
        }


        case fromRoles.DELETE_ROLE: {
            return { ...state, loading: true };
        }

        case fromRoles.DELETE_ROLE_SUCCESS: {
            return { ...state, loading: false, loaded: true };
        }
    }

    return state;
}

export const getRolesEntities = (state: RoleState) => state.entities;
export const getRolesLoading = (state: RoleState) => state.loading;
export const getRolesLoaded = (state: RoleState) => state.loaded;
export const getTotalRoles = (state: RoleState) => state.total;
export const getRolesImporting = (state: RoleState) => state.importing;
export const getRolesImported = (state: RoleState) => state.imported;

export const getAllRolesEntities = (state: RoleState) => state.allRoles;
export const getAllRolesLoading = (state: RoleState) => state.allLoading;
export const getAllRolesLoaded = (state: RoleState) => state.allLoaded;
