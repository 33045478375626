import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SegmentService } from 'ngx-segment-analytics';
import { BehaviorSubject } from 'rxjs';
import { SegmentNameEnum, SegmentTitlePage } from '../../segment/segment.enum';
import { MixpanelTrackingProvider } from './mixpanel-tracking.provider';
import { SegmentTrackingProvider } from './segment-tracking.provider';
import { TrackingProvider } from './tracking-provider.interface';

@Injectable({
  providedIn: 'root'
})
export class TrackingSegmentService {
  account: string;
  organizationName: string;
  currentUrl: string;
  title: string;
  name: string;
  trackingProvider: TrackingProvider;

  public properties = new BehaviorSubject(null);
  public dialogExportType: string;
  public candidateInfo = new BehaviorSubject(null);

  constructor(
    private router: Router,
  ) {
    this.account = localStorage.getItem('currentAccount');
    this.organizationName = localStorage.getItem('organizationName');
    this.currentUrl = this.router?.url;
    this.title = SegmentTitlePage[this.currentUrl]
    this.name = SegmentNameEnum[this.currentUrl]

    this.candidateInfo.next(JSON.parse(localStorage.getItem('candidateInfos')));

    this.trackingProvider = new MixpanelTrackingProvider();
  }

  public track(title: string, properties?: any):void {
    properties.account = (!properties?.account ? this.account : properties?.account);
    this.trackingProvider.track(title, properties);
  }

  public page(title: string, properties?: any):void {
    properties.account = (!properties.account ? this.account : properties?.account);
    this.trackingProvider.trackPageView(title, properties);
  }

  public identify(user: string, properties: any):void {
    this.trackingProvider.identifyUser(user, properties)
  }

  public getOrganizationName(): string {
    return this.organizationName || '';
  }
}
