import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromPreferences from '../reducers/preferences.reducer';

export const getPreferenceState = createSelector(
    fromFeature.getFilterState,
    (state: fromFeature.DashboardState) => state.preferences
);

export const getPreferencesEntities = createSelector(
    getPreferenceState,
    fromPreferences.getPreferencesEntities
);

export const getAllPreferences = createSelector(
    getPreferencesEntities,
    entities => entities
);

export const getPreferencesLoaded = createSelector(
    getPreferenceState,
    fromPreferences.getPreferencesLoaded
);
export const getPreferencesLoading = createSelector(
    getPreferenceState,
    fromPreferences.getPreferencesLoading
);
