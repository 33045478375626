import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';

import { Store, select } from '@ngrx/store';

import * as fromStore from '../_store';
import { Router } from '@angular/router';

@Injectable()
export class CollaboratorsGuard implements CanActivate {
    constructor(
        private store: Store<fromStore.DashboardState>,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree {
        let collaborator: boolean;

        this.store.pipe(select(fromStore.getModulesEntities)).subscribe(res => {
            collaborator = res.collaborators;
        });

        if (collaborator) {
            return true;
        } else {
            this.router.navigateByUrl('/');
            return false;
        }
    }
}
