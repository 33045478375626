import { Action } from '@ngrx/store';

/**
 * Load Organization
 */
export const LOAD_ORGANIZATION = '[Dashboard] Load Organization';
export const LOAD_ORGANIZATION_SUCCESS = '[Dashboard] Load Organization Success';
export const LOAD_ORGANIZATION_FAILURE = '[Dashboard] Load Organization Fail';

export class LoadOrganization implements Action {
    readonly type = LOAD_ORGANIZATION;
    constructor(public payload: any) {}
}

export class LoadOrganizationSuccess implements Action {
    readonly type = LOAD_ORGANIZATION_SUCCESS;
    constructor(public payload: any) {}
}

export class LoadOrganizationFail implements Action {
    readonly type = LOAD_ORGANIZATION_FAILURE;
    constructor(public payload: any) {}
}

/**
 * Change Account
 */
export const CHANGE_ACCOUNT = '[Dashboard] Change Account';
export class ChangeAccout implements Action {
    readonly type = CHANGE_ACCOUNT;
    constructor(public payload: any) {}
}

export type LoadOrganizationAction =
    | LoadOrganization
    | LoadOrganizationSuccess
    | LoadOrganizationFail
    | ChangeAccout;
