import * as fromOrganization from '../actions/organizations.action';

export interface OrganizationState {
    groups: any;
    currentAccount: any;
    loaded: boolean;
    loading: boolean;
}

export const initialState: OrganizationState = {
    groups: [],
    currentAccount: {},
    loaded: false,
    loading: false
};

export function reducer(
    state = initialState,
    action: fromOrganization.LoadOrganizationAction
): OrganizationState {
    switch (action.type) {
        case fromOrganization.LOAD_ORGANIZATION: {
            return { ...state, loading: true };
        }

        case fromOrganization.LOAD_ORGANIZATION_SUCCESS: {
            const current = localStorage.getItem('currentAccount');
            const groups = action.payload.callback;
            let currentAccount = groups[0].accounts[0];
            const companies = groups.reduce((all, item) => {
                item.accounts.map(res => {
                    all.push(res);
                });
                return all;
            }, []);

            if (current !== null) {
                currentAccount = companies.filter(
                    x => x.uid === localStorage.getItem('currentAccount')
                )[0];
            }

            return {
                ...state,
                groups,
                currentAccount,
                loaded: true,
                loading: false
            };
        }

        case fromOrganization.LOAD_ORGANIZATION_FAILURE: {
            return { ...state, loading: false, loaded: false };
        }

        case fromOrganization.CHANGE_ACCOUNT: {
            const currentAccount = action.payload;
            return { ...state, currentAccount, loading: false, loaded: true };
        }
    }
    return state;
}

export const getOrganizationGroups = (state: OrganizationState) => state.groups;
export const getOrganizationCurrent = (state: OrganizationState) =>
    state.currentAccount;
export const getOrganizationLoading = (state: OrganizationState) =>
    state.loading;
export const getOrganizationLoaded = (state: OrganizationState) => state.loaded;
