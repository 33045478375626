import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromOrganization from '../reducers/organizations.reducer';

export const getOrganizationState = createSelector(fromFeature.getFilterState, (state: fromFeature.DashboardState) => state?.organization);
export const getOrganizationGroups = createSelector(getOrganizationState, fromOrganization.getOrganizationGroups);
export const getOrganizationLoaded = createSelector(getOrganizationState, fromOrganization.getOrganizationLoaded);

export const getAllGroups = createSelector(getOrganizationGroups, entities => {
    return Object.keys(entities).map(id => entities[id]);
});
