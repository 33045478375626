import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

declare let environment;

@Injectable({ providedIn: 'root' })
export class EncryptService {
    private keySize = 256;
    private ivSize = 128;
    private saltSize = 256;
    private iterations = 1000;
    private sign_id: string = environment.sign_id;

    constructor() {}

    hexToBase64(str) {
        return btoa(
            String.fromCharCode.apply(
                null,
                str
                    .replace(/\r|\n/g, '')
                    .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
                    .replace(/ +$/, '')
                    .split(' ')
            )
        );
    }

    base64ToHex(str) {
        for (
            var i = 0, bin = atob(str.replace(/[ \r\n]+$/, '')), hex = [];
            i < bin.length;
            ++i
        ) {
            var tmp = bin.charCodeAt(i).toString(16);
            if (tmp.length === 1) tmp = '0' + tmp;
            hex[hex.length] = tmp;
        }
        return hex.join('');
    }

    decrypt(transitmessage) {
        const hexResult = this.base64ToHex(transitmessage);
        const salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
        const iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
        const encrypted = this.hexToBase64(hexResult.substring(96));
        const key = CryptoJS.PBKDF2(this.sign_id, salt, {
            keySize: this.keySize / 32,
            iterations: this.iterations
        });
        const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    encrypt(message) {
        const salt = CryptoJS.lib.WordArray.random(this.saltSize / 8);
        const key = CryptoJS.PBKDF2(this.sign_id, salt, {
            keySize: this.keySize / 32,
            iterations: this.iterations
        });
        const iv = CryptoJS.lib.WordArray.random(this.ivSize / 8);
        const encrypted = CryptoJS.AES.encrypt(message, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });
        const encryptedHex = this.base64ToHex(encrypted.toString());
        const base64result = this.hexToBase64(salt.concat(iv) + encryptedHex);
        return base64result;
    }
}
