<aside
	class="sidebar-account"
	#container
	data-testid="sidebar-container"
	[ngClass]="{'slide-menu-active': showMenu}"
	(click)="toggleMenu()"
>
	<div (click)="$event.stopImmediatePropagation();" class="wrapper-menu" [ngClass]="{'wrapper-menu-active': showMenu}">
		<div class="sidebar-account-menu">
			<div class="header-sidebar">
				<img src="./assets/images/core/icone-unico.svg" alt="unico | people" width="40">
				<button type="button" aria-label="Fecha menu lateral" data-testid="back-button" (click)="toggleMenu()"></button>
			</div>
			<div class="sidebar-menu first">
				<h3>Empresa</h3>
				<ul>
					<li class="open-companies-container">
						<button class="open-companies-mini" type="button" data-testid="companies-button" (click)="toggleTab('settings')"
							attr.aria-label="Abrir aba de empresas da {{acc?.name}}.">
							<span class="current-company-mini-image" [attr.data-name]="acc?.name | slice:0:1">
								<img *ngIf="acc?.customFields?.brand !== null"
									src="{{url}}/t/200x200,q100/{{acc?.customFields?.brand?.path}}" alt="Logo da empresa">
							</span>
							<p class="current-company-mini-title" title="{{acc?.name}}">{{acc?.name}}</p>
						</button>
					</li>
					<li>
						<a routerLink="/" (click)="toggleMenu()" aria-label="Ir para listagem de candidatos."
							class="ico-admission">Admissão</a>
					</li>
					<li *ngIf="hasCollaborators">
            			<a class="title ico-colaborators" href="{{ongoingUrl}}" (click)="toggleMenu()" aria-label="Ir para a listagem de colaboradores." data-test="linkOngoingDashboard">Colaboradores</a>
					</li>
					<li *ngIf="enableEnvelopesManagement">
						<details class="collapse">
							<summary class="title-collapse ico-signatures menu-signatures">
							<p>
							  Assinaturas
							</p>
						  </summary>
						  <ul>
							<li>
								<a routerLink="/signatures" (click)="toggleMenu()" aria-label="Ir para gestão de assinaturas."
									class="link">
									<img src="/assets/images/core/ic-mail.svg" alt="Gestão de envelopes">
									Gestão de assinaturas</a>
							</li>
							<li>
								<a *ngIf="!enableNewModelsList" routerLink="/company/models" (click)="toggleMenu()" aria-label="Ir para gestão de modelos."
									class="link">
									<img src="/assets/images/core/ic-files.svg" alt="Listar modelos">
									Gestão de modelos</a>

								<a *ngIf="enableNewModelsList" routerLink="/company/models-list" (click)="toggleMenu()" aria-label="Ir para gestão de modelos."
									class="link">
									<img src="/assets/images/core/ic-files.svg" alt="Listar modelos">
									Gestão de modelos</a>
							</li>
						  </ul>
						</details> 
					  </li>
					<li>
						<a routerLink="/company" data-testid="settings-button" (click)="toggleMenu()" aria-label="Ir para as configurações da empresa."
							class="ico-settings">Configurações</a>
					</li>
					<li *ngIf="showBenefitsProductivity">
						<a href="{{benefitsURL}}" (click)="toggleMenu()" aria-label="Ir para produtividade." class="ico-productivity">Produtividade</a>
					</li>
				</ul>
			</div>
			<div class="sidebar-menu">
				<h3>Grupo</h3>
				<ul>
					<li *ngIf="hasSignature">
						<a (click)="redirectToSign()" aria-label="Ir para as configurações da empresa."
							class="ico-sign">Unico Sign</a>
					</li>
					<ng-container *ngIf="hasBackupEnabled$ | async as hasBackupEnabled">
						<li *ngIf="hasBackupEnabled?.match">
							<a href="/backup" (click)="trackerEvent()" aria-label="Ir para backup de dados." class="ico-backup">Backup
								de dados</a>
						</li>
					</ng-container>
				</ul>
			</div>
		</div>
		<div class="sidebar-company-submenu sidebar-submenu" data-testid="companies-container" [ngClass]="{'open' : company }">
			<app-companies [companies]="list" [acc]="acc?.uid" (change)="changeCompany($event)"></app-companies>
		</div>
	</div>
</aside>
