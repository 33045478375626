import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

declare let environment: any;

@Injectable({ providedIn: 'root' })
export class PermissionsService {
    private url: string = environment.url;

    constructor(private http: HttpClient) {}

    /**
     * Get account permissions
     * @param access_token token
     */
    getAccountsPermissions(access_token): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${access_token}`
            }),
            withCredentials: true
        };
        return this.http.get<any>(
            `${this.url}/permissions/accounts`,
            httpOptions
        );
    }

    /**
     * Get units permissions
     * @param access_token token
     */
    getUnitsPermissions(access_token): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${access_token}`
            }),
            withCredentials: true
        };
        return this.http.get<any>(`${this.url}/permissions/units`, httpOptions);
    }

    /**
     * Get current unit permissions
     * @param uid unit uid
     */
    getCurrentUnitPermissions(uid: string): Observable<any> {
        return this.http.get<any>(`${this.url}/permissions/units?id=${uid}`);
    }
}
