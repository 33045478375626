import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromDashboardStore from '@app/modules/admission/_store/selectors';

declare let environment: any;

@Injectable({ providedIn: 'root' })
export class DepartmentsService {
    private url: string = environment.url;
    private acc: string;

    constructor(private http: HttpClient, private store: Store<any>) {
        this.store
            .pipe(select(fromDashboardStore.getOrganizationState))
            .subscribe(res => {
                this.acc = res.currentAccount.uid;
            });
    }

    /**
     * Get all departments
     */
    loadAll(): Observable<any> {
        return this.http.get<any>(
            `${this.url}/departments?account=${this.acc}&sort=name`
        );
    }

    /**
     * Get paginated departments
     */
    loadPaginated(): Observable<any> {
        return this.http.get<any>(
            `${this.url}/departments?account=${this.acc}&skip=0&limit=10&sort=name`
        );
    }

    /**
     * Load more departments
     */
    loadMore(skip: number, limit: number): Observable<any> {
        return this.http.get<any>(
            `${this.url}/departments?account=${this.acc}&skip=${skip}&limit=${limit}&sort=name`
        );
    }

    /**
     * Search departments
     */
    search(query: string): Observable<any> {
        return this.http.get<any>(
            `${this.url}/search/departments?account=${this.acc}&q=${query}`
        );
    }

    /**
     * Creat a new department
     * @param value department name
     */
    create(value): Observable<any> {
        return this.http.post<any>(`${this.url}/department`, {
            account: this.acc,
            ...value
        });
    }

    /**
     * Delete departments
     * @param ids array of uids that will delete
     * @param all boolean to delete all departments
    */
    delete(ids?: Array<string>, all?: boolean): Observable<any> {
        const body = {
            account: this.acc
        };
        ids.length ? body['ids'] = ids : body['all'] = true;
        return this.http.post<any>(`${this.url}/departments/delete`, body);
    }

    /**
    * Import departments
    * @param objID ID from document in storage reviewed on preview
    */
    import(objID): Observable<any> {
        const data = {
            objectID: objID,
            account: this.acc,
            forceUpdate: true,
            ignoreErrors: false
        };
        return this.http.put<any>(`${this.url}/departments/import`, data);
    }
}
