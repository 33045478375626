import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

declare let environment: any;

@Injectable({
    providedIn: 'root'
})
export class PreferencesService {
    private readonly url: string = environment.url;

    constructor(private http: HttpClient) {}

    getPreferences(data): Observable<any> {
        return this.http.get(`${this.url}/preferences/list`, data);
    }

    updatePreference(data): Observable<any> {
        return this.http.patch(`${this.url}/preference`, data);
    }
}
