<div class="container-companies">
	<div class="header-companies">
		<h2 data-testid="companies-title">Empresas</h2>
		<p data-testid="companies-message">Escolha uma empresa do grupo para visualizar ou administrar.</p>
	</div>
	<ul class="companies-list" *ngFor="let company of companies;">
		<p data-testid="companies-name">{{company.name}}</p>
		<li *ngFor="let account of company.accounts;" data-testid="companies-item">
			<button (click)="changeCompany(account?.uid)" type="button"
				attr.aria-label="Trocar para a empresa {{account.name}}" [ngClass]="{'active': account?.uid === acc}">
				<span class="current-company-image" [attr.data-name]="account.name | slice:0:1">
					<img *ngIf="account?.customFields?.brand !== null"
						src="{{url}}/t/200x200,q100/{{account?.customFields?.brand?.path}}" alt="">
				</span>
				<span>{{account?.name}}</span>
			</button>
		</li>
	</ul>
</div>