import * as fromUnits from '@app/modules/admission/_store/actions/units.action';

import { Unit } from '@app/core/_shared/models/units.model';

export interface UnitState {
    entities: Array<Unit>;
    allUnits: Array<Unit>;
    loaded: boolean;
    allLoaded: boolean;
    loading: boolean;
    allLoading: boolean;
}

export const initialState: UnitState = {
    entities: [],
    allUnits: [],
    loaded: false,
    allLoaded: false,
    loading: false,
    allLoading: false
};

export function reducer(
    state = initialState,
    action: fromUnits.UnitAction
): UnitState {
    switch (action.type) {
        case fromUnits.LOAD_UNITS: {
            return { ...state, loading: true };
        }

        case fromUnits.LOAD_UNITS_SUCCESS: {
            const units = action?.payload?.results;
            const entities = units?.reduce(
                (all: { [uid: string]: Unit }, item: Unit) => {
                    return { ...all, [item.uid]: item };
                },
                {}
            );

            return { ...state, loading: false, loaded: true, entities };
        }

        case fromUnits.LOAD_UNITS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false
            };
        }

        case fromUnits.LOAD_MORE_UNITS: {
            return { ...state, loading: true };
        }

        case fromUnits.LOAD_MORE_UNITS_SUCCESS: {
            const arr1 = Object.values(state?.entities);
            const arr2 = action?.payload?.results;
            const units = [...arr1, ...arr2];

            const entities = units.reduce(
                (all: { [uid: string]: Unit }, item: Unit) => {
                    return { ...all, [item.uid]: item };
                },
                {}
            );

            return { ...state, loading: false, loaded: true, entities };
        }

        case fromUnits.LOAD_MORE_UNITS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false
            };
        }

        case fromUnits.LOAD_ALL_UNITS: {
            return { ...state, allLoading: true };
        }

        case fromUnits.LOAD_ALL_UNITS_SUCCESS: {
            const units = action?.payload?.results;

            const allUnits = units.reduce(
                (all: { [uid: string]: Unit }, item: Unit) => {
                    return { ...all, [item.uid]: item };
                },
                {}
            );

            return { ...state, allLoading: false, allLoaded: true, allUnits };
        }

        case fromUnits.LOAD_ALL_UNITS_FAIL: {
            return {
                ...state,
                allLoading: false,
                allLoaded: false
            };
        }

        case fromUnits.SEARCH_UNITS: {
            return { ...state, loading: true };
        }

        case fromUnits.SEARCH_UNITS_SUCCESS: {
            const units = action?.payload?.results;

            const entities = units.reduce(
                (all: { [uid: string]: Unit }, item: Unit) => {
                    return { ...all, [item.uid]: item };
                },
                {}
            );

            return { ...state, loading: false, loaded: true, entities };
        }

        case fromUnits.SEARCH_UNITS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false
            };
        }
    }

    return state;
}

export const getUnitsEntities = (state: UnitState) => state.entities;
export const getUnitsLoading = (state: UnitState) => state.loading;
export const getUnitsLoaded = (state: UnitState) => state.loaded;

export const getAllUnitsEntities = (state: UnitState) => state.allUnits;
export const getAllUnitsLoading = (state: UnitState) => state.allLoading;
export const getAllUnitsLoaded = (state: UnitState) => state.allLoaded;
