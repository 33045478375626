import { DepartmentEffects } from './departments.effect';
import { UnitEffects } from './units.effect';
import { RoleEffects } from './roles.effect';
import { OrganizationEffects } from './organizations.effect';
import { ClinicsEffects } from './clinics.effect';
import { PermissionEffects } from './permissions.effect';
import { ModuleEffects } from './modules.effect';
import { PreferencesEffects } from './preferences.effect';

export const effects: any[] = [
    DepartmentEffects,
    UnitEffects,
    RoleEffects,
    OrganizationEffects,
    ClinicsEffects,
    PermissionEffects,
    ModuleEffects,
    PreferencesEffects
];

export * from './departments.effect';
export * from './units.effect';
export * from './roles.effect';
export * from './organizations.effect';
export * from './clinics.effect';
export * from './permissions.effect';
export * from './modules.effect';
export * from './preferences.effect';
