import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

declare let environment;

export interface FeatureFlagModel {
  flag_key: string;
  entity_id: string;
  context: {
    organization_id: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private url: string = environment.url;

  constructor(private http: HttpClient) {}

  /**
   * @param {FeatureFlagModel} data for requisition in flip
   * @returns Boolean feature flag rule returns
   */
  hasFlag(data: FeatureFlagModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-store, no-cache, max-age=0, must-revalidate, proxy-revalidate',
      }),
    };
    return this.http.post(`${this.url}/tools/flags/evaluate`, data, httpOptions).pipe(
      catchError((error) => of({ match: false }))
    );
  }
}
