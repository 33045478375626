(function ($) {
  'use strict';

  let environment = {
    production: false,
    current_environment: 'local',
    url: 'http://svc1.acessorh.localhost',
    url_ongoing: 'http://localhost:8087/api/v1',
    url_benefit: 'http://admin.acessorh.localhost/v1',
    cdn_url: 'http://cdn1.acessorh.localhost',
    identity_url: 'http://identity.acesso.localhost',
    base_url: 'http://admin.acessorh.localhost',
    benefits_url: 'http://benefits.acessorh.localhost',
    sign_url: 'http://localhost:3000',
    sign_id: 'DB8EF2DD966F2CE9F3237F1A1746143B346393D373C7268FFC32759DE06597EA',
    url_grpc: 'http://admin.acessorh.localhost',
    grpc_url: 'http://admin.acessorh.localhost',
    redirect_uri: 'http://admin.acessorh.localhost/preauthorize',
    url_ongoing_dashboard: 'http://localhost:4400',
    segmentApiKey: 'aJSx6ITwDTBcFEq6je022Y0UrC52Khm1',
    base_sign_url: 'https://sign-dev.acesso.io/bff/v1',
    productId: '99c3dfc5-7518-4d7c-9e75-e54d920721fd',
    launchDarklyClientId: '63ea5db5e87179139977b661',
    app_version: '',
    sentry_enabled: true,
    sentry_dsn: 'https://e015580df539410dbe66f584e43893a7@o939397.ingest.sentry.io/4505206237364224',
    sentry_ignore_errors: null,
    sentry_ignore_status_code: null,
    sign_envelopes_management_url: 'https://sign-core-dev.acesso.io/api/v1',
    sign_callback_url: 'http://admin.acessorh.localhost',
    sign_people_product_id: '99c3dfc5-7518-4d7c-9e75-e54d920721fd',
    report_versions: '{ "summary": ["1", "2", "3"], "csv": ["1", "1.1", "2", "2.1", "2.2", "2.3", "3", "3.1", "3.1.1", "3.2", "3.3"], "csv-ec": ["1", "2", "3"], "apdata-employee": ["1", "1.1"], "fpw-employee": ["1", "2", "2.1"], "fpw-dependents": ["1", "1.1", "2"], "csv-peoplesoft": ["2"], "csv-sharp": ["1", "2"] }',
    mixpanelToken: 'f4069d9b550ea08e34089810c2abd5be',
  };

  if (typeof define === 'function' && define.amd) {
    define(function () {
      return environment;
    });
  } else if (typeof module === 'object' && module.exports) {
    module.exports = environment;
  } else {
    $.environment = environment;
  }
})(window);
