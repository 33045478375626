import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Store, select } from '@ngrx/store';

import { Observable } from 'rxjs';

import * as fromDashboardStore from '@app/modules/admission/_store/selectors';

declare let environment: any;

@Injectable({ providedIn: 'root' })
export class ClinicsService {
    private url: string = environment.url;
    private acc: string;

    constructor(private http: HttpClient, private store: Store<any>) {
        this.store
            .pipe(select(fromDashboardStore.getOrganizationState))
            .subscribe((res) => {
                this.acc = res.currentAccount.uid;
            });
    }

    /**
     * Get paginated clinics
     */
    loadPaginated(): Observable<any> {
        return this.http.get<any>(
            `${this.url}/clinics?account=${this.acc}&skip=0&limit=10&sort=name`
        );
    }

    /**
     * Get all clinics
     */
    loadAll(): Observable<any> {
        return this.http.get<any>(`${this.url}/clinics?account=${this.acc}&sort=name`);
    }

    /**
     * Search clinics
     */
    search(query: string): Observable<any> {
        return this.http.get<any>(
            `${this.url}/search/clinics?account=${this.acc}&q=${query}`
        );
    }

    /**
     * Load more clinics
     */
    loadMore(skip: number, limit: number): Observable<any> {
        return this.http.get<any>(
            `${this.url}/clinics?account=${this.acc}&skip=${skip}&limit=${limit}&sort=name`
        );
    }

    /**
     * Creat a new clinic
     * @param value clinic name
     */
    create(value): Observable<any> {
        return this.http.post<any>(`${this.url}/clinic`, value);
    }

    /**
     * Delete clinics
     * @param ids array of uids that will delete
     * @param all boolean to delete all clinics
     */
    delete(ids?: Array<string>, all?: boolean): Observable<any> {
        const body = {
            account: this.acc,
        };
        ids.length ? (body['ids'] = ids) : (body['all'] = true);
        return this.http.post<any>(`${this.url}/clinics/delete`, body);
    }

    /**
     * Import clinics
     * @param objID ID from document in storage reviewed on preview
     */
    import(objID): Observable<any> {
        const data = {
            objectID: objID,
            account: this.acc,
            forceUpdate: true,
            ignoreErrors: false,
        };
        return this.http.put<any>(`${this.url}/clinics/import`, data);
    }
}
