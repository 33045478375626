import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { RoleData } from '@app/core/_shared/models/roles.model';
import { Observable } from 'rxjs';

import * as fromDashboardStore from '@app/modules/admission/_store/selectors';

declare let environment: any;

@Injectable({ providedIn: 'root' })
export class RolesService {
    private url: string = environment.url;
    private acc: string;

    constructor(private http: HttpClient, private store: Store<any>) {
        this.store
            .pipe(select(fromDashboardStore.getOrganizationState))
            .subscribe((res) => {
                this.acc = res.currentAccount.uid;
            });
    }

    /**
     * Create Role
     * @param roleData object that contain properties to create a role
     */
    create(roleData: RoleData): Observable<any> {
        return this.http.post<any>(`${this.url}/role`, {
            account: this.acc,
            name: roleData.name,
            code: roleData.code,
            safedoc: roleData.safedoc,
            context: {
                brazil: {
                    cbo: roleData.cbo,
                    escolaridadeMinima: roleData.escolaridadeMinima,
                },
            },
        });
    }

    /**
     * Load paginated roles
     * ? Initial get roles setting manually skip and limit
     */
    read(): Observable<any> {
        return this.http.get<any>(
            `${this.url}/roles?account=${this.acc}&skip=0&limit=10&sort=name`
        );
    }

    /**
     * Load more roles
     * @param skip offset number
     * @param limit total roles on database
     */
    loadMore(skip: Number, limit: Number): Observable<any> {
        return this.http.get<any>(
            `${this.url}/roles?account=${this.acc}&skip=${skip}&limit=${limit}&sort=name`
        );
    }

    /**
     * Load all roles
     */
    loadAll(): Observable<any> {
        return this.http.get<any>(
            `${this.url}/roles?account=${this.acc}&sort=name`
        );
    }

    /**
     * Search roles
     * @param query term to search
     */
    search(query: string): Observable<any> {
        return this.http.get<any>(
            `${this.url}/search/roles?account=${this.acc}&q=${query}`
        );
    }

    /**
     * Delete roles
     * @param ids array of uids that will delete
     * @param all boolean to delete all roles
     */
    delete(ids?: Array<string>, all?: boolean): Observable<any> {
        const body = {
            account: this.acc,
        };
        ids.length ? (body['ids'] = ids) : (body['all'] = true);
        return this.http.post<any>(`${this.url}/roles/delete`, body);
    }

    /**
     * Import roles
     * @param objID ID from document in storage reviewed on preview
     */
    import(objID: string): Observable<any> {
        const data = {
            objectID: objID,
            account: this.acc,
            forceUpdate: true,
            ignoreErrors: false,
        };
        return this.http.put<any>(`${this.url}/roles/import`, data);
    }

    /**
     * Validates CBO number of roles
     * @param roleCode CBO number
     */
    validCbo(roleCode): Observable<any> {
        return this.http.get<any>(
            `${this.url}/brazil/cbo/validate?code=${roleCode}`
        );
    }
}
