import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';

import { Environment } from '@app/core/_shared/interfaces/environment/environment.interface';

declare const environment: Environment;

interface Token {
  token: string;
  status: number;
  error: string;
}

export interface CodeChallenge {
  raw: string;
  method: string;
}

interface Authorize {
  email: string;
  error: string;
  id: string;
  name: string;
  organization: string;
  organizationName: string;
  status: number;
}

@Injectable({ providedIn: 'root' })
export class CallbackService {
  private readonly url: string = environment.url;
  private readonly redirectUri: string = environment.redirect_uri;

  constructor(private http: HttpClient) {}

  /**
   * Takes callback code and returns a token
   * @param code a token that Identity pass via a callback URL
   * @returns an object with a valid token
   */
  getToken(code: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        redirectURI: this.redirectUri,
        code: code
      }),
      withCredentials: true
    };

    return this.http.get<Token>(`${this.url}/authorize/code`, httpOptions);
  }

  /**
   * Takes the code challenge for pkce authentication flow
   * @returns an object with code challenge
   */
  getCodeChallenge() {
    return this.http.get<CodeChallenge>(`${this.url}/authorize/challenge`);
  }

  /**
   * Takes the token and returns user infos and organization id
   * @param token a valid token
   * @returns an object with user infos and organization id
   */
  getAuthorization(token: string): any {
    return this.http.get<any>(`${this.url}/authorize`, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      observe: 'response',
      withCredentials: true
    });
  }
}
