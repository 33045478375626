import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { applyPolyfills, defineCustomElements as defineProductMgmtExp } from '@acesso-io/unico-sign-octopus/loader'

import { AppModule } from './app/app.module';

import * as Sentry from "@sentry/angular-ivy";
import { BrowserTracing } from '@sentry/angular-ivy';

import { environment } from './environments/environment';
import { HttpErrorResponse } from '@angular/common/http';

  Sentry.init({
    environment: environment.production ? "production" : "non production",
    dsn: environment.sentry_dsn,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: [/^\//],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(
        {
          maskAllText: true,
          blockAllMedia: true,
        }
      ),
    ],
    beforeSend(event, hint) {
      if (hint?.originalException instanceof HttpErrorResponse) {
        return null;
      }
      return event;
    },
    ignoreErrors: makeIgnoreErrors(),
    enableTracing: true,
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.1,
  });

function makeIgnoreErrors() {
  let ignore = null;
  try {
    ignore = eval(environment?.sentry_ignore_errors);
  } catch (error) {
    // pass
  }
  return ignore;
}
if (!window.msw) {
  if (environment.production) {
    sethotjar();
    enableProdMode();
  } else {
    setTestEnvironment();
  }
}
function sethotjar() {
  const scriptHotjar = document.createElement('script');
  scriptHotjar.innerHTML = `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3031290,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`

  document.head.appendChild(scriptHotjar);
}

function setTestEnvironment () {
  //  Hotjar Tracking Code for https://test-form.acessorh.com.br
  const scriptHotjar = document.createElement('script');
  scriptHotjar.innerHTML = `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3114551,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`

  document.head.appendChild(scriptHotjar);
}

platformBrowserDynamic().bootstrapModule(AppModule);

applyPolyfills().then(() => {
  defineProductMgmtExp(window)
})
