import { Action } from '@ngrx/store';

export const LOAD_MODULES = '[Dashboard] Load Modules';
export const LOAD_MODULES_SUCCESS = '[Dashboard] Load Modules Success';
export const LOAD_MODULES_FAIL = '[Dashboard] Load Modules Fail';

export class LoadModules implements Action {
    readonly type = LOAD_MODULES;
    /**
     * Load Modules
     * @param payload token JWT
     */
    constructor(public payload: any) {}
}

export class LoadModulesSuccess implements Action {
    readonly type = LOAD_MODULES_SUCCESS;
    constructor(public payload: any) {}
}

export class LoadModulesFail implements Action {
    readonly type = LOAD_MODULES_FAIL;
    constructor(public payload: any) {}
}

export type ModuleAction = LoadModules | LoadModulesSuccess | LoadModulesFail;
