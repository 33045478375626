import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SegmentModule } from 'ngx-segment-analytics';
import { AppRoutingModule } from './app-routing.module';
import { Environment } from './core/_shared/interfaces/environment/environment.interface';

import { EffectsModule } from '@ngrx/effects';
import { DefaultRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { MetaReducer, StoreModule } from '@ngrx/store';

import { CustomSerializer, reducers } from '@app/core/_store';

import { environment as env } from '../environments/environment';

import { JwtModule } from '@auth0/angular-jwt';
import { AppComponent } from './app.component';
declare const environment: Environment;

export function tokenGetter() {
  return localStorage.getItem('token');
}

// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { LaunchdarklyService } from '@acesso-io/launchdarkly-client-ts/lib/launchdarkly';
import { Router } from '@angular/router';
import { GlobalErrorHandler } from '@app/core/_shared/services/error/error.service';
import { TokenInterceptor } from '@app/core/_shared/services/interceptor/interceptor.service';
import * as Sentry from "@sentry/angular-ivy";
import { CallbackModule } from './callback/callback.module';
import initializeAppFactory from './core/_shared/services/app-initializer/app-initializer.factory';
import { ContentLayoutModule } from './layout/content-layout/content-layout.module';
import { MaintenanceModule } from './maintenance/maintenance.module';

export const metaReducers: MetaReducer<any>[] = [];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    ClipboardModule,
    CallbackModule,
    MaintenanceModule,
    ContentLayoutModule,
    NgxPermissionsModule.forRoot(),
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      headerName: 'Acesso-Csrf-Token',
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      serializer: DefaultRouterStateSerializer
    }),
    !env.production ? StoreDevtoolsModule.instrument() : [],
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    SegmentModule.forRoot({ apiKey: '', debug: false, loadOnInitialization: false })
  ],
  declarations: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LaunchdarklyService, useFactory: () => new LaunchdarklyService(environment.launchDarklyClientId)},
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [LaunchdarklyService],
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
